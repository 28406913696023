import { NativeTokens, NETWORK } from '../../defaults';
import {
  AWSGroupScheme,
  DBBridge,
  DBBridges,
  DBMiscSettings,
  DBToken,
  DBTokens,
  LockedNonces,
  Sell,
  UserLog,
} from '../../types';
import { Competitors } from '../../types/monitoring';
import {
  CheckerRes,
  CheckerResItem,
  EVENTER_EVENTS,
  MODULE_EVENTS_PARAMS,
  NODER_EVENTS,
  TokenPins,
  TX_EVENTS,
  TxPeerStatus,
  WORKER_EVENTS,
} from '../zmq';
import { CommandRequest } from '../http';
import { ZMQServicePeerStatusMap } from '../../types/brain';

/**
 *
 */
export enum BRAIN_EVENTS {
  BR_BRAIN_STATE = 'BR_BRAIN_STATE',
  BR_INIT = 'BR_INIT',
  BR_NOTIFY = 'BR_NOTIFY',
  BR_SOUND = 'BR_SOUND',
  BR_EVENT_CACHE = 'BR_EVENT_CACHE',
  BR_COMMAND = 'BR_COMMAND',

  BR_PING = 'BR_PING',
  BR_STOP_RECONNECT = 'BR_STOP_RECONNECT',
  BR_DISCONNECT_USER = 'BR_DISCONNECT_USER',

  BR_NEW_USER_LOG = 'BR_NEW_USER_LOG',
  BR_ADD_USER_LOG = 'BR_ADD_USER_LOG',

  BR_ADD_CHECKER_RES = 'BR_ADD_CHECKER_RES',
  BR_DEL_CHECKER_RES = 'BR_DEL_CHECKER_RES',

  BR_CONNECTED_USERS = 'BR_CONNECTED_USERS',
  BR_CONNECTED_SERVICES = 'BR_CONNECTED_SERVICES',

  BR_RESTART_SRV_SCALING = 'BR_RESTART_SRV_SCALING',
  BR_RESTART_AWS = 'BR_RESTART_AWS',
  BR_UPDATE_MISC_SETTINGS = 'BR_UPDATE_MISC_SETTINGS',
  BR_UPDATE_MISC_SETTINGS_DONE = 'BR_UPDATE_MISC_SETTINGS_DONE',

  BR_AWS_UPDATE_DONE = 'BR_AWS_UPDATE_DONE',

  BR_CREATE_TOKEN_DONE = 'BR_CREATE_TOKEN_DONE',
  BR_DELETE_TOKEN_DONE = 'BR_DELETE_TOKEN_DONE',
  BR_UPDATE_TOKEN_DONE = 'BR_UPDATE_TOKEN_DONE',

  BR_CREATE_BRIDGE_DONE = 'BR_CREATE_BRIDGE_DONE',
  BR_DELETE_BRIDGE_DONE = 'BR_DELETE_BRIDGE_DONE',
  BR_UPDATE_BRIDGE_DONE = 'BR_UPDATE_BRIDGE_DONE',

  /**
   * ZMQ Proxy events
   */
  ND_BLOCK = 'ND_BLOCK',
  ND_GAS = 'ND_GAS',
  ND_BALANCE = 'ND_BALANCE',
  ND_SECOND_BALANCE = 'ND_SECOND_BALANCE',
  EV_STATS = 'EV_STATS',
  WR_STATS = 'WR_STATS',
  WR_ANALYTICS = 'WR_ANALYTICS',
  WR_EVENTS = 'WR_EVENTS',
  WR_LP_STAT = 'WR_LP_STAT',
  WR_ITER_RES = 'WR_ITER_RES',
  WR_ITER_ERR = 'WR_ITER_ERR',
  TX_TRADE_CREATED = 'TX_TRADE_CREATED',
  TX_SENT = 'TX_SENT',
  TX_CREATED = 'TX_CREATED',
  TX_CONFIRMED = 'TX_CONFIRMED',
  TX_TRADE_COMPLETED = 'TX_TRADE_COMPLETED',
}

/**
 *
 */
export type BRAIN_EVENTS_PARAMS = {
  [BRAIN_EVENTS.BR_INIT]: {
    devMode: boolean;
    msRemainingBeforeDisconnect: number;
    allTokens: DBTokens;
    competitors: Competitors;
    bridges: DBBridges;
    miscSettings: DBMiscSettings;
    awsGroups: AWSGroupScheme[];
    brainState: BRAIN_EVENTS_PARAMS[BRAIN_EVENTS.BR_BRAIN_STATE];
    connectedServices: BRAIN_EVENTS_PARAMS[BRAIN_EVENTS.BR_CONNECTED_SERVICES];
    checkerRes: CheckerRes;
  };
  [BRAIN_EVENTS.BR_COMMAND]: CommandRequest,
  [BRAIN_EVENTS.BR_NOTIFY]: {
    msg: string;
    type: 'info' | 'warning' | 'error';
    autoClose?: false | number;
  };
  [BRAIN_EVENTS.BR_SOUND]: { name: string; };
  [BRAIN_EVENTS.BR_EVENT_CACHE]: {
    newHeads: {
      [key in NETWORK]?: MODULE_EVENTS_PARAMS[NODER_EVENTS.ND_BLOCK];
    };
    gasEstimate: {
      [key in NETWORK]?: MODULE_EVENTS_PARAMS[NODER_EVENTS.ND_GAS];
    };
    balance: { [key in NETWORK]?: { [tokenName: string]: string } };
    secondaryBalance: {
      [walletAddress: string]: {
        [networkName in NETWORK]?: {
          [tokenName: string]: string;
        };
      };
    };

    trackingResults: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WR_ITER_RES];
  };
  [BRAIN_EVENTS.BR_DISCONNECT_USER]: { name: string; blockReconnect: boolean };
  [BRAIN_EVENTS.BR_PING]: {};
  [BRAIN_EVENTS.BR_STOP_RECONNECT]: {};
  [BRAIN_EVENTS.BR_ADD_USER_LOG]: { u: string; l: string };
  [BRAIN_EVENTS.BR_NEW_USER_LOG]: UserLog;
  [BRAIN_EVENTS.BR_ADD_CHECKER_RES]: CheckerResItem;
  [BRAIN_EVENTS.BR_DEL_CHECKER_RES]: string;
  [BRAIN_EVENTS.BR_BRAIN_STATE]: {
    walletAddress: string;
    workersRunning: boolean;
    workersStartedBy: string;
    routeSearchRunning: boolean;
    routeSearchProgress: {
      [key: string]: number;
    };
    pinnedTokens: TokenPins;
    autosellPins: TokenPins;
    pendingSells: Sell[];
    txPeerStatus: TxPeerStatus;
    zmqPeerStatus: ZMQServicePeerStatusMap;
    lockedNonces: LockedNonces;
    wethPriceUSD: string;
    nativePriceUSD: { [key in NativeTokens]: string };
  };
  [BRAIN_EVENTS.BR_CONNECTED_USERS]: {
    [username: string]: number;
  };
  [BRAIN_EVENTS.BR_CONNECTED_SERVICES]: {
    [serviceName: string]: {
      numExpected: number;
      numConnected: number;
    };
  };
  [BRAIN_EVENTS.BR_RESTART_SRV_SCALING]: {
    deploymentName: string;
    namespace: string;
  };
  [BRAIN_EVENTS.BR_RESTART_AWS]: void;

  [BRAIN_EVENTS.BR_UPDATE_MISC_SETTINGS]: {
    updates: Partial<DBMiscSettings>;
  };

  [BRAIN_EVENTS.BR_AWS_UPDATE_DONE]: {
    awsGroups: AWSGroupScheme[];
  };

  [BRAIN_EVENTS.BR_UPDATE_MISC_SETTINGS_DONE]: {
    miscSettings: DBMiscSettings;
  };

  [BRAIN_EVENTS.BR_CREATE_TOKEN_DONE]: {
    tokenName: string;
    tokenData: DBToken;
  };

  [BRAIN_EVENTS.BR_DELETE_TOKEN_DONE]: {
    tokenName: string;
  };

  [BRAIN_EVENTS.BR_UPDATE_TOKEN_DONE]: {
    tokenName: string;
    tokenData: DBToken;
  };

  [BRAIN_EVENTS.BR_CREATE_BRIDGE_DONE]: {
    bridgeName: string;
    bridgeData: DBBridge;
  };
  [BRAIN_EVENTS.BR_DELETE_BRIDGE_DONE]: {
    bridgeName: string;
    updatedTokens: DBTokens;
  };
  [BRAIN_EVENTS.BR_UPDATE_BRIDGE_DONE]: {
    bridgeName: string;
    bridgeData: DBBridge;
  };

  /**
   * ZMQ Proxy events
   */
  [BRAIN_EVENTS.ND_BLOCK]: MODULE_EVENTS_PARAMS[NODER_EVENTS.ND_BLOCK];
  [BRAIN_EVENTS.ND_GAS]: MODULE_EVENTS_PARAMS[NODER_EVENTS.ND_GAS];
  [BRAIN_EVENTS.ND_BALANCE]: MODULE_EVENTS_PARAMS[NODER_EVENTS.ND_BALANCE];
  [BRAIN_EVENTS.ND_SECOND_BALANCE]: MODULE_EVENTS_PARAMS[NODER_EVENTS.ND_SECOND_BALANCE];
  [BRAIN_EVENTS.EV_STATS]: MODULE_EVENTS_PARAMS[EVENTER_EVENTS.EV_STATS];
  [BRAIN_EVENTS.WR_STATS]: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WR_STATS];
  [BRAIN_EVENTS.WR_ANALYTICS]: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WR_ANALYTICS];
  [BRAIN_EVENTS.WR_EVENTS]: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WR_EVENTS];
  [BRAIN_EVENTS.WR_LP_STAT]: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WR_LP_STAT];
  [BRAIN_EVENTS.WR_ITER_RES]: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WR_ITER_RES];
  [BRAIN_EVENTS.WR_ITER_ERR]: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WR_ITER_ERR];
  [BRAIN_EVENTS.TX_TRADE_CREATED]: MODULE_EVENTS_PARAMS[TX_EVENTS.TX_TRADE_CREATED];
  [BRAIN_EVENTS.TX_SENT]: MODULE_EVENTS_PARAMS[TX_EVENTS.TX_SENT];
  [BRAIN_EVENTS.TX_CREATED]: MODULE_EVENTS_PARAMS[TX_EVENTS.TX_CREATED];
  [BRAIN_EVENTS.TX_CONFIRMED]: MODULE_EVENTS_PARAMS[TX_EVENTS.TX_CONFIRMED];
  [BRAIN_EVENTS.TX_TRADE_COMPLETED]: MODULE_EVENTS_PARAMS[TX_EVENTS.TX_TRADE_COMPLETED];
};

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type __ = {
  [Property in BRAIN_EVENTS]: BRAIN_EVENTS_PARAMS[Property];
};
