import { NETWORK } from '../../defaults';
import { DBBridge, DBMiscSettings, DBToken, DBTokens, RoutesData } from '../../types';

export type PinKey = `${NETWORK}_${NETWORK}_${string}`;

export enum PinType {
  AUTO = 'auto',
  FORCE = 'force',
  PARALLEL = 'parallel',
  NO_TRADE = 'no_trade',
}

export type TokenPinData = {
  [key in PinKey]?: PinType;
};

export type TokenPins = { [key: string]: TokenPinData };

export enum CheckerCode {
  SUCCESS,
  ERROR,
  TOKEN_CHECK,
  BALANCE,
  PROFIT,
  NO_TRADE,
  AUTOMATION,
}

export function checkerCodeToText(code: CheckerCode): string {
  switch (code) {
    case CheckerCode.AUTOMATION:
      return 'AUTOMATION';
    case CheckerCode.TOKEN_CHECK:
      return 'TOKEN_CHECK';
    case CheckerCode.BALANCE:
      return 'BALANCE';
    case CheckerCode.PROFIT:
      return 'PROFIT';
    case CheckerCode.NO_TRADE:
      return 'NO_TRADE';
  }
  return '?';
}

export type CheckerRes = {
  [iterId: string]: CheckerResItem;
};

export type CheckerResItem = {
  iterId: string;
  code: CheckerCode;
  createdAt: number;
  err?: string;
  reason?: string;
};

/**
 *
 */
export enum BRAIN_EVENTS {
  BR_CONNECT = 'BR_CONNECT',
  BR_DISCONNECT = 'BR_DISCONNECT',
  BR_ZMQ_PEER_STATUS = 'BR_ZMQ_PEER_STATUS',
  BR_START_NODER = 'BR_START_NODER',
  BR_START_TX = 'BR_START_TX',
  BR_NOTIFY = 'BR_NOTIFY',
  BR_UPDATE_TOKEN_DONE = 'BR_UPDATE_TOKEN_DONE',
  BR_UPDATE_CONF_DONE = 'BR_UPDATE_CONF_DONE',

  /**
   * UI Proxied events
   */
  BR_START_WORKERS = 'BR_START_WORKERS',
  BR_STOP_WORKERS = 'BR_STOP_WORKERS',
  BR_START_ROUTE_SEARCH = 'BR_START_ROUTE_SEARCH',
  BR_COMMAND = 'BR_COMMAND',
  BR_RESTART_SERVICE = 'BR_RESTART_SERVICE',
  BR_UPDATE_MISC_SETTINGS = 'BR_UPDATE_MISC_SETTINGS',
  BR_CREATE_TOKEN = 'BR_CREATE_TOKEN',
  BR_DELETE_TOKEN = 'BR_DELETE_TOKEN',
  BR_UPDATE_TOKEN = 'BR_UPDATE_TOKEN',
  BR_CREATE_BRIDGE = 'BR_CREATE_BRIDGE',
  BR_DELETE_BRIDGE = 'BR_DELETE_BRIDGE',
  BR_UPDATE_BRIDGE = 'BR_UPDATE_BRIDGE',
  BR_TOGGLE_BRIDGE_AUTOMATION = 'BR_TOGGLE_BRIDGE_AUTOMATION',
  BR_CLEAR_CACHED_RESULTS = 'BR_CLEAR_CACHED_RESULTS',
  BR_LIST_CONF = 'BR_LIST_CONF',
  BR_GET_CONF = 'BR_GET_CONF',
  BR_UPDATE_CONF = 'BR_UPDATE_CONF',
}

/**
 *
 */
export type BRAIN_EVENTS_PARAMS = {
  [BRAIN_EVENTS.BR_CONNECT]: void;
  [BRAIN_EVENTS.BR_DISCONNECT]: void;
  [BRAIN_EVENTS.BR_ZMQ_PEER_STATUS]: {
    peer: string;
    statuses: { [peer: string]: number; }
  };
  [BRAIN_EVENTS.BR_START_NODER]: {
    walletAddress: string;
  };
  [BRAIN_EVENTS.BR_START_TX]: {
    globalAutomationEnabled: boolean;
    sellOnly: boolean;
    // e2e only
    bundleAddr?: string;
  };

  [BRAIN_EVENTS.BR_START_ROUTE_SEARCH]: {
    miscSettings: DBMiscSettings;
    activeTokensSplitted: DBTokens;
    routesData: RoutesData | null;
  };

  [BRAIN_EVENTS.BR_NOTIFY]: {
    msg: string;
    type: 'info' | 'warning' | 'error';
    autoClose?: false | number;
  };
  [BRAIN_EVENTS.BR_UPDATE_CONF_DONE]: { configName: string; configData: string };

  /**
   * UI Proxied events
   */
  [BRAIN_EVENTS.BR_START_WORKERS]: void;
  [BRAIN_EVENTS.BR_STOP_WORKERS]: void;

  [BRAIN_EVENTS.BR_START_ROUTE_SEARCH]: {
    miscSettings: DBMiscSettings;
    activeTokensSplitted: DBTokens;
    routesData: RoutesData | null;
  };

  [BRAIN_EVENTS.BR_COMMAND]: {
    to: string;
    cmd: string;
  };

  [BRAIN_EVENTS.BR_UPDATE_TOKEN_DONE]: {
    tokenName: string;
    tokenData: DBToken;
  };

  [BRAIN_EVENTS.BR_RESTART_SERVICE]: {
    serviceName: string;
  };

  [BRAIN_EVENTS.BR_UPDATE_MISC_SETTINGS]: {
    updates: Partial<DBMiscSettings>;
  };

  [BRAIN_EVENTS.BR_CREATE_TOKEN]: {
    tokenName: string;
  };
  [BRAIN_EVENTS.BR_DELETE_TOKEN]: {
    tokenName: string;
  };
  [BRAIN_EVENTS.BR_UPDATE_TOKEN]: {
    tokenName: string;
    updates: Partial<DBToken>;
  };

  [BRAIN_EVENTS.BR_CREATE_BRIDGE]: {
    bridgeName: string;
  };
  [BRAIN_EVENTS.BR_DELETE_BRIDGE]: {
    bridgeName: string;
  };
  [BRAIN_EVENTS.BR_UPDATE_BRIDGE]: {
    bridgeName: string;
    updates: Partial<DBBridge>;
  };

  [BRAIN_EVENTS.BR_TOGGLE_BRIDGE_AUTOMATION]: {
    bridgeName: string;
    newValue: boolean;
  };
  [BRAIN_EVENTS.BR_CLEAR_CACHED_RESULTS]: {};

  [BRAIN_EVENTS.BR_LIST_CONF]: {};
  [BRAIN_EVENTS.BR_GET_CONF]: { configName: string };
  [BRAIN_EVENTS.BR_UPDATE_CONF]: { configName: string; configData: string };
};

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
  [Property in BRAIN_EVENTS]: BRAIN_EVENTS_PARAMS[Property];
};
