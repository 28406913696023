import Abstract from './Abstract.png';
import Arbitrum from './Arbitrum.png';
import Base from './Base.png';
import Binance from './Binance.png';
import Ethereum from './Ethereum.png';
import Fantom from './Fantom.png';
import Gnosis from './Gnosis.png';
import Optimism from './Optimism.png';
import Polygon from './Polygon.png';
import PulseChain from './PulseChain.png';
import Snowtrace from './Snowtrace.png';
import Solana from './Solana.png';
import Sonic from './Sonic.png';
import TON from './TON.png';
import ATA from './ATA.png';
import Linea from './Linea.png';
import Blast from './Blast.png';
import Unichain from './Unichain.png';
import Berachain from './Berachain.png';
import Metis from './Metis.png';
import Tron from './Tron.png';

import CEXBinance from './cex_binance.png';
import CEXBitget from './cex_bitget.png';
import CEXBithumb from './cex_bithumb.png';
import CEXBitmart from './cex_bitmart.png';
import CEXBitrue from './cex_bitrue.png';
import CEXBybit from './cex_bybit.png';
import CEXCoinbase from './cex_coinbase.png';
import CEXCryptocom from './cex_cryptocom.png';
import CEXGateio from './cex_gateio.png';
import CEXHtx from './cex_htx.png';
import CEXKucoin from './cex_kucoin.png';
import CEXMexc from './cex_mexc.png';
import CEXOkx from './cex_okx.png';
import CEXPoloiex from './cex_poloniex.png';

import Default from './Default.png';

const NETWORK_IMAGES: {
  [networkName: string]: string;
  Default: string;
} = {
  Abstract,
  Arbitrum,
  Base,
  Binance,
  Ethereum,
  Fantom,
  Gnosis,
  Optimism,
  Polygon,
  PulseChain,
  Snowtrace,
  Solana,
  TON,
  ATA,
  Linea,
  Blast,
  Unichain,
  Berachain,
  Sonic,
  Metis,
  Tron,

  CEXBinance,
  CEXBitget,
  CEXBithumb,
  CEXBitmart,
  CEXBitrue,
  CEXBybit,
  CEXCoinbase,
  CEXCryptocom,
  CEXGateio,
  CEXHtx,
  CEXKucoin,
  CEXMexc,
  CEXOkx,
  CEXPoloiex,

  Default: Default,
};

export function getNetworkImage(networkName: string) {
  return NETWORK_IMAGES[networkName] || NETWORK_IMAGES.Default;
}
