import { NETWORK } from '../../defaults';

/**
 *
 */
export enum EVENTER_EVENTS {
  EV_STATS = 'EV_STATS',
  EV_LOGS = 'EV_LOGS',
  EV_777_LOGS = 'EV_777_LOGS',
}

/**
 *
 */
export type EVENTER_EVENTS_PARAMS = {
  [EVENTER_EVENTS.EV_STATS]: string;
  [EVENTER_EVENTS.EV_LOGS]: {
    network: NETWORK;
    multiId?: string;
    logs: any[];
  };
  [EVENTER_EVENTS.EV_777_LOGS]: {
    network: NETWORK;
    multiId?: string;
    logs: any[];
  };
};

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
  [Property in EVENTER_EVENTS]: EVENTER_EVENTS_PARAMS[Property];
};
