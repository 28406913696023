import { Mutex } from 'async-mutex';

import {
  Id,
  toast,
  ToastContent,
  ToastOptions,
  ToastPromiseParams,
} from 'react-toastify';
import { CloseIcon } from './svg';

const ToastState = {
  mux: new Mutex(),
  count: 0,
};

export function CloseAllToast() {
  const style: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0.2em',
    right: '3px',
    zIndex: 9999,
    height: '24px',
    width: '320px',
    backgroundColor: '#adb5bd',
    border: '2px solid #adb5bd',
    color: 'white',
    margin: '0',
    padding: '8px',
    borderRadius: 0,
    boxSizing: 'border-box',
    cursor: 'pointer',
  };
  return ToastState.count > 1 ? (
    <div style={style} onClick={() => toast.dismiss()}>
      <CloseIcon style={{ width: 18, height: 18 }} />
    </div>
  ) : null;
}

const getToastHandlers = () => {
  return {
    onOpen() {
      ToastState.mux.acquire().then((release) => {
        ToastState.count = ToastState.count + 1;
        release();
      });
    },
    onClose() {
      ToastState.mux.acquire().then((release) => {
        ToastState.count = Math.max(0, ToastState.count - 1);
        release();
      });
    },
  };
};

export const addToast = (content: ToastContent, opts: ToastOptions): Id => {
  return toast(content, {
    ...opts,
    ...getToastHandlers(),
  });
};

export const successToast = (content: ToastContent, opts?: ToastOptions): Id =>
  addToast(content, {
    ...(opts ?? {}),
    type: 'success',
  });
export const errorToast = (content: ToastContent, opts?: ToastOptions): Id =>
  addToast(content, {
    ...(opts ?? {}),
    type: 'error',
  });
export const warnToast = (content: ToastContent, opts?: ToastOptions): Id =>
  addToast(content, {
    ...(opts ?? {}),
    type: 'warning',
  });
export const infoToast = (content: ToastContent, opts?: ToastOptions): Id =>
  addToast(content, {
    ...(opts ?? {}),
    type: 'info',
  });
export const promiseToast = (
  promise: Promise<unknown>,
  params: ToastPromiseParams,
  opts?: ToastOptions,
): Promise<unknown> => {
  return toast.promise(promise, params, {
    ...opts,
    ...getToastHandlers(),
  });
};
