import React, { memo, useCallback, useEffect, useState } from 'react';

import { getLogoSrc } from './Header';
import Sound from '../modules/Sound';
import { useAuth } from '../context/auth.context';

/**
 *
 */
type LoaderProps = {
  projectName?: string;
  buildNumber?: string;
};
export default memo(function Loader({ projectName, buildNumber }: LoaderProps) {
  const { userAuthenticated } = useAuth();
  const [clicked, setClicked] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [helperShown, setHelperShown] = useState(false);

  const removeEffects = useCallback(() => {
    setRemoved(true);
    document.body.style.overflowY = 'scroll';
  }, []);

  useEffect(() => {
    setRemoved(false);
    document.body.style.overflowY = 'hidden';
    return () => removeEffects();
  }, []);

  useEffect(() => {
    const _timeout = setTimeout(() => {
      setHelperShown(true);
    }, 4000);
    return () => clearTimeout(_timeout);
  }, []);

  if (!userAuthenticated || removed) {
    return <></>;
  }

  return (
    <div
      className="loaderWrapper"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setClicked(true);
        Sound.play('plop');
        setTimeout(() => {
          removeEffects();
        }, 300);
      }}
    >
      <div className={'loaderOverlay ' + (!clicked ? 'shown' : '')}></div>
      <img
        className={'loaderImg ' + (!clicked ? 'maximize' : '')}
        src={getLogoSrc()}
        alt=""
      />
      <p
        className={'helperText ' + (helperShown ? 'shown' : '')}
        style={{ paddingBottom: '10px' }}
      >
        [ press anywhere to continue ]
      </p>
      <p className={'loaderText ' + (!clicked ? 'shown' : '')}>
        {projectName}
        <mark style={{ top: '-60px', transform: 'translateX(calc(150px - 50%))' }}>
          {import.meta.env.VITE_NODE_ENV === 'development' ? 'DEVELOPMENT BUILD' : ''}
        </mark>
        <span>{buildNumber ? '#' + buildNumber : ''}</span>
      </p>
    </div>
  );
});
