import { io, Socket } from 'socket.io-client';

import { WS_EVENTS, WS_EVENTS_PARAMS } from '../common/events/ws';
import { appWsEvents, wsHandlers } from './handlers';
import { Updater } from 'use-immer';
import { AppState } from '../context/app.context';
import Sound from '../modules/Sound';
import { errorToast, warnToast } from '../components/Toast';
import SecureLocalStorage from '../modules/SecureLocalStorage';

class WS {
  private url!: string;

  private socket?: Socket;

  init(url: string) {
    this.url = url;
  }

  async connect(setAppState: Updater<AppState>): Promise<void> {
    const apiKey = SecureLocalStorage.getItem('USER_API_KEY');
    if (!apiKey) {
      console.error(`api key not found`);
      window.location.href = '/login';
      return;
    }

    this.socket = io(this.url, {
      autoConnect: false,
      auth: {
        token: apiKey,
      },
      query: {
        subscribe: JSON.stringify(appWsEvents),
      },
    });

    this.socket.on('disconnect', () => {
      if (this.socket) {
        this.socket.close();
      }
      this.handleDisconnect(setAppState);
    });

    Object.keys(wsHandlers).forEach((ev) => {
      const handler = wsHandlers[ev as WS_EVENTS](setAppState);
      this.socket!.on(ev as WS_EVENTS, handler);
    });

    this.socket.connect();
  }

  on(event: WS_EVENTS, handler: (data: any) => void) {
    this.socket?.on(event, handler);
  }

  off(event: WS_EVENTS, handler: (data: any) => void) {
    this.socket?.off(event, handler);
  }

  emit<T extends WS_EVENTS>(moduleEvent: T, moduleEventData: WS_EVENTS_PARAMS[T]) {
    this.socket?.emit(moduleEvent, moduleEventData);
  }

  disconnect() {
    this.socket?.disconnect();
  }

  handleDisconnect(setAppState: Updater<AppState>) {
    Sound.play('error');
    setAppState((draft) => {
      draft.msRemainingBeforeDisconnect = 0;
    });

    const shouldReconnect = localStorage.getItem('reconn') !== '0';
    if (!shouldReconnect) {
      errorToast('Disconnected from server', {
        autoClose: false,
        closeOnClick: true,
        closeButton: true,
      });
      return;
    }

    warnToast('Disconnected from server. Reconnecting...', {
      autoClose: false,
      closeOnClick: true,
      closeButton: true,
    });
    setTimeout(() => this.connect(setAppState), 5000);
  }
}

export default new WS();
