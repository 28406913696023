/**
 *
 */
export enum CRONER_EVENTS {
  CR_TASK_RESULT = 'CR_TASK_RESULT',
  CR_TASK_COMPLETE = 'CR_TASK_COMPLETE',
}

/**
 *
 */
export type CRONER_EVENTS_PARAMS = {
  [CRONER_EVENTS.CR_TASK_RESULT]: {
    task: string
    res: any
  }
  [CRONER_EVENTS.CR_TASK_COMPLETE]: {
    task: string
  }
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
  [Property in CRONER_EVENTS]: CRONER_EVENTS_PARAMS[Property]
}
