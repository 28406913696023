import { BRAIN_EVENTS, WS_EVENTS_PARAMS } from '../events/ws';
import { NativeTokens } from '../defaults';

export type ZMQServicePeerStatuses = {
  [peer: string]: number;
}

export type ZMQServicePeerStatusMap = {
  [peer: string]: ZMQServicePeerStatuses;
}

export const DEFAULT_BRAIN_STATE: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_BRAIN_STATE] =
  {
    walletAddress: '',
    workersRunning: false,
    workersStartedBy: '',
    routeSearchRunning: false,
    routeSearchProgress: {},
    pinnedTokens: {},
    autosellPins: {},
    pendingSells: [],
    lockedNonces: {},
    wethPriceUSD: '0',
    txPeerStatus: {} as any,
    zmqPeerStatus: {},
    nativePriceUSD: {
      [NativeTokens.ETH]: '0',
      [NativeTokens.BNB]: '0',
      [NativeTokens.FTM]: '0',
      [NativeTokens.XDAI]: '0',
      [NativeTokens.POL]: '0',
      [NativeTokens.AVAX]: '0',
      [NativeTokens.SOL]: '0',
      [NativeTokens.S]: '0',
      [NativeTokens.BERA]: '0',
      [NativeTokens.TRX]: '0',
      [NativeTokens.METIS]: '0',
      [NativeTokens.TON]: '0',
    },
  };

