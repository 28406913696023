import Swal from 'sweetalert2';
import { MergedTxHistory } from '../common/types';

type SingleInputParams = {
  title: string;
  text?: string;
  placeholder?: string;
  value: string;
  validator: (v: string) => string | null;
  didOpen?: (swal: typeof Swal) => void;
  onNewValue: (v: string) => void;
  secondButtonText?: string;
  onSecondButton?: () => void;
  width?: number;
};

type ConfirmParams = {
  title: string;
  text?: string;
  onConfirm: () => void;
  onCancel?: () => void;
};

export default class Popup {
  static popupMessage(title: string, text: string, width?: number) {
    const params: any = { title, text };
    if (!!width && width > 100) {
      params.width = width;
    }
    SwalStyled.fire(params);
  }

  static popupJSON(title: string, json: any) {
    const formattedJson = JSON.stringify(json, null, 4);

    SwalStyled.fire({
      title,
      width: '95%',
      html: `<pre>${formattedJson}</pre>`,
    });
  }

  static popupTxsHistory(tradeId: string, history: MergedTxHistory) {
    const title = `${tradeId} all txs data`;
    const inner = Object.keys(history).map(key => {
      const [side, method, attempt] = key.split(':');
      const formattedJson = JSON.stringify(history[key], null, 4);
      return `<div style="display: flex; flex-direction: column; margin-bottom: 12px;">
        <div style="display: flex; margin-bottom: 8px;">
            <p style="margin-right: 32px;"><b>Side</b>: ${side}</p>
            <p style="margin-right: 32px;"><b>Send Method</b>: ${method}</p>
            <p><b>Attempt</b>: ${attempt}</p>
        </div>
        <pre>${formattedJson}</pre>
      </div>`;
    });
    const html = `<div style="display: flex; flex-direction: column;">${inner}</div>`;
    SwalStyled.fire({ title, width: '95%', html });
  }

  static confirm({ title, text, onConfirm, onCancel }: ConfirmParams) {
    SwalStyled.fire({
      title,
      html: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        onConfirm();
      } else {
        onCancel && onCancel();
      }
    });
  }

  static singleInput(params: SingleInputParams) {
    const {
      title,
      text,
      value,
      validator,
      onNewValue,
      didOpen,
      secondButtonText,
      onSecondButton,
      width,
    } = params;

    const options = {
      title,
      html: text,
      input: 'text',
      inputValue: value,
      inputAttributes: {
        autocomplete: 'off',
      },
      inputPlaceholder: params.placeholder ?? '',
      inputValidator: validator,
      didOpen: () => {
        didOpen && didOpen(SwalStyled);
      },
      confirmButtonText: 'Save',
      allowEnterKey: true,
      showDenyButton: !!secondButtonText && !!onSecondButton,
      denyButtonText: secondButtonText,
    } as any;

    if (!!width && width > 400) {
      options.width = width;
    }

    SwalStyled.fire(options).then((result) => {
      if (!!secondButtonText && result.isDenied && onSecondButton) {
        onSecondButton();
      }
      if (result.isConfirmed && result.value !== value) {
        onNewValue(result.value);
      }
    });
  }

  static singleSelect(
    title: string,
    text: string,
    value: string,
    inputOptionsMap: Object,
    onNewValue: (v: string) => void,
  ) {
    SwalStyled.fire({
      title,
      html: text,
      input: 'select',
      inputValue: value,
      inputOptions: { '': '', ...inputOptionsMap },
      confirmButtonText: 'Save',
    }).then((result) => {
      if (result.isConfirmed && result.value !== value) {
        onNewValue(result.value);
      }
    });
  }
}

export const SwalStyled = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-outline-primary',
    cancelButton: 'btn btn-outline-secondary',
    denyButton: 'btn btn-outline-danger',
    actions: 'd-grid d-flex gap-2',
    title: 'h2',
  },
  buttonsStyling: false,
  showCloseButton: true,
  allowEnterKey: false,
  allowEscapeKey: true,
});
