import {NETWORK} from "../../defaults";

/**
 *
 */
export enum NODER_EVENTS {
  ND_BLOCK = "ND_BLOCK",
  ND_SYNC_GAS = "ND_SYNC_GAS",
  ND_GAS = "ND_GAS",
  ND_SYNC_BALANCE = "ND_SYNC_BALANCE",
  ND_BALANCE = "ND_BALANCE",
  ND_SECOND_BALANCE = "ND_SECOND_BALANCE",
  ND_NONCE = "ND_NONCE",
  ND_SECOND_NONCE = "ND_SECOND_NONCE",
}

/**
 *
 */
export type NODER_EVENTS_PARAMS = {
  [NODER_EVENTS.ND_BLOCK]: {
    network: NETWORK;
    num: number;
    time: number;
  };
  [NODER_EVENTS.ND_SYNC_GAS]: void;
  [NODER_EVENTS.ND_GAS]: {
    network: NETWORK;
    gas: number;
  };
  [NODER_EVENTS.ND_SYNC_BALANCE]: {
    tokenName: string;
    network: NETWORK;
  };
  [NODER_EVENTS.ND_BALANCE]: {
    network: NETWORK;
    balance: { [tokenName: string]: string };
  };
  [NODER_EVENTS.ND_SECOND_BALANCE]: {
    network: NETWORK;
    address: string;
    balance: { [tokenName: string]: string };
  };
  [NODER_EVENTS.ND_NONCE]: {
    [networkName in NETWORK]: number;
  };
  [NODER_EVENTS.ND_SECOND_NONCE]: {
    address: string;
    nonces: { [networkName in NETWORK]: number };
  };
};

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
  [Property in NODER_EVENTS]: NODER_EVENTS_PARAMS[Property];
};
