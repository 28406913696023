import { WorkerIterationErrors, WorkerIterationResults } from '../../types';

/**
 *
 */
export enum WORKER_EVENTS {
  WR_STATS = 'WR_STATS',
  WR_ANALYTICS = 'WR_ANALYTICS',
  WR_EVENTS = 'WR_EVENTS',
  WR_LP_STAT = 'WR_LP_STAT',
  WR_ITER_RES = 'WR_ITER_RES',
  WR_ITER_ERR = 'WR_ITER_ERR',
}

/**
 *
 */
export type WORKER_EVENTS_PARAMS = {
  [WORKER_EVENTS.WR_STATS]: string;
  [WORKER_EVENTS.WR_ANALYTICS]: string;
  [WORKER_EVENTS.WR_EVENTS]: string;
  [WORKER_EVENTS.WR_LP_STAT]: string;
  [WORKER_EVENTS.WR_ITER_RES]: WorkerIterationResults;
  [WORKER_EVENTS.WR_ITER_ERR]: WorkerIterationErrors;
};

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
  [Property in WORKER_EVENTS]: WORKER_EVENTS_PARAMS[Property];
};
