import { TokenShortArrayData } from '../types/token';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export enum REQUESTER {
  '1INCH' = 'inch',
  PARASWAP = 'paraswap',
  ZEROX = 'zerox',
  KYBERSWAP = 'kyberswap',
  ODOS = 'odos',
  LP = 'lp',
  JUPITER = 'jupiter',
}

export enum NETWORK {
  ARBITRUM = 'Arbitrum',
  BASE = 'Base',
  BINANCE = 'Binance',
  ETHEREUM = 'Ethereum',
  FANTOM = 'Fantom',
  GNOSIS = 'Gnosis',
  OPTIMISM = 'Optimism',
  POLYGON = 'Polygon',
  PULSECHAIN = 'PulseChain',
  SNOWTRACE = 'Snowtrace',
  SOLANA = 'Solana',
  TON = 'TON',
  BLAST = 'Blast',
  LINEA = 'Linea',
  UNICHAIN = 'Unichain',
  BERACHAIN = 'Berachain',
  SONIC = 'Sonic',
  METIS = 'Metis',
  TRON = 'Tron',
  ATA = 'ATA',
  ABSTRACT = 'Abstract',
  CEXBinance = "CEXBinance",
  CEXBitget = "CEXBitget",
  CEXBithumb = "CEXBithumb",
  CEXBitmart = "CEXBitmart",
  CEXBitrue = "CEXBitrue",
  CEXBybit = "CEXBybit",
  CEXCoinbase = "CEXCoinbase",
  CEXCryptocom = "CEXCryptocom",
  CEXGateio = "CEXGateio",
  CEXHtx = "CEXHtx",
  CEXKucoin = "CEXKucoin",
  CEXMexc = "CEXMexc",
  CEXOkx = "CEXOkx",
  CEXPoloiex = "CEXPoloiex",
}

export const evmNetworks = Object.values(NETWORK).filter(
  (n) =>
    n !== NETWORK.SOLANA &&
    n !== NETWORK.TON &&
    n !== NETWORK.TRON &&
    n !== NETWORK.ATA,
) as NETWORK[];

export enum DEX {
  Apeswap_V2 = 'Apeswap_V2',
  Biswap_V2 = 'Biswap_V2',
  Camelot_V2 = 'Camelot_V2',
  Camelot_V3 = 'Camelot_V3',
  Pancakeswap_V2 = 'Pancakeswap_V2',
  Pancakeswap_V3 = 'Pancakeswap_V3',
  Quickswap_V2 = 'Quickswap_V2',
  Quickswap_V3 = 'Quickswap_V3',
  Shibaswap_V2 = 'Shibaswap_V2',
  Sushiswap_V2 = 'Sushiswap_V2',
  TraderJoe_V1 = 'TraderJoe_V1',
  TraderJoe_V2 = 'TraderJoe_V2',
  Uniswap_V2 = 'Uniswap_V2',
  Uniswap_V3 = 'Uniswap_V3',
}

export enum BRIDGE {
  ANYSWAP = 'AnySwap',
  MULTICHAIN = 'MultiChain',
  POLYBRIDGE = 'PolyBridge',
  CHAINPORT = 'Chainport',
  CHAINPORTBURN = 'ChainportBurn',
  CHAINPORTX = 'ChainportX',
  CROSSCHAIN = 'CrossChain',
  SYNAPSE = 'Synapse',
  POLYGON = 'PolygonBridge',
  ARBITRUM = 'ArbitrumBridge',
  HELLO = 'HelloBridge',
  PORTAL = 'PortalBridge',
  RAINI = 'Raini',
  POWBLOCKS = 'PowblocksBridge',
  FERRUM = 'Ferrum',
  SPORES = 'SPORES',
  PAID_NETWORK = 'PaidNetwork',
  STARGATE = 'Stargate',
  SUPER = 'SuperBridge',
  BURGERSWAP = 'BurgerSwap',
  WAN = 'WanBridge',
  WINKY = 'WinkyverseBridge',
  NERVE = 'NerveBridge',
  ZRO_BRIDGE = 'ZROBridge',
  OMNI_BRIDGE = 'OmniBridge',
  AXELAR_BRIDGE = 'Axelar',
  CCIP_BRIDGE = 'CCIP',
  DE_BRIDGE = 'deBridge',
  OPTIMISM = 'OptimismBridge',
  UNI = 'UniBridge',
  CONSTRUCTOR = 'Constructor',
  CONSTRUCTOR_CLAIM = 'ConstructorClaim',
}

export enum NativeTokens {
  ETH = 'ETH',
  BNB = 'BNB',
  FTM = 'FTM',
  XDAI = 'XDAI',
  POL = 'POL',
  AVAX = 'AVAX',
  SOL = 'SOL',
  TON = 'TON',
  S = 'S',
  TRX = 'TRX',
  METIS = 'METIS',
  BERA = 'BERA',
}

// @ts-ignore
export const NETWORK_NATIVE_TOKEN: {
  [networkName in NETWORK]: string /** tokenName */;
} = {
  [NETWORK.ARBITRUM]: 'ETH',
  [NETWORK.BASE]: 'ETH',
  [NETWORK.BINANCE]: 'BNB',
  [NETWORK.ETHEREUM]: 'ETH',
  [NETWORK.FANTOM]: 'FTM',
  [NETWORK.GNOSIS]: 'XDAI',
  [NETWORK.OPTIMISM]: 'ETH',
  [NETWORK.POLYGON]: 'MATIC',
  [NETWORK.PULSECHAIN]: 'PLS',
  [NETWORK.SNOWTRACE]: 'AVAX',
  [NETWORK.SOLANA]: 'SOL',
  [NETWORK.TON]: 'TON',
  [NETWORK.ATA]: 'ATA',
  [NETWORK.BLAST]: 'ETH',
  [NETWORK.LINEA]: 'ETH',
  [NETWORK.UNICHAIN]: 'ETH',
  [NETWORK.BERACHAIN]: 'BERA',
  [NETWORK.SONIC]: 'S',
  [NETWORK.TRON]: 'TRX',
  [NETWORK.METIS]: 'METIS',
  [NETWORK.ABSTRACT]: 'ETH',
};

// @ts-ignore
export const NETWORK_TO_CHAIN_ID: {
  [networkName in NETWORK]: number; // https://chainlist.org/
} = {
  [NETWORK.ARBITRUM]: 42161,
  [NETWORK.BASE]: 8453,
  [NETWORK.BINANCE]: 56,
  [NETWORK.ETHEREUM]: 1,
  [NETWORK.FANTOM]: 250,
  [NETWORK.GNOSIS]: 100,
  [NETWORK.OPTIMISM]: 10,
  [NETWORK.POLYGON]: 137,
  [NETWORK.PULSECHAIN]: 369,
  [NETWORK.SNOWTRACE]: 43114,
  [NETWORK.SOLANA]: 0,
  [NETWORK.TON]: 0,
  [NETWORK.ATA]: 0,
  [NETWORK.BLAST]: 81457,
  [NETWORK.LINEA]: 59144,
  [NETWORK.UNICHAIN]: 130,
  [NETWORK.BERACHAIN]: 80094,
  [NETWORK.SONIC]: 146,
  [NETWORK.METIS]: 1088,
  [NETWORK.TRON]: 728126428,
  [NETWORK.ABSTRACT]: 2741,
};

// @ts-ignore
export const NETWORK_EXPLORER_ADDRESS: {
  [networkName in NETWORK]: string /** explorerUrl */;
} = {
  [NETWORK.ARBITRUM]: 'https://arbiscan.io/',
  [NETWORK.BASE]: 'https://basescan.org/',
  [NETWORK.BINANCE]: 'https://bscscan.com/',
  [NETWORK.ETHEREUM]: 'https://etherscan.io/',
  [NETWORK.FANTOM]: 'https://ftmscan.com/',
  [NETWORK.GNOSIS]: 'https://gnosisscan.io/',
  [NETWORK.OPTIMISM]: 'https://optimistic.etherscan.io/',
  [NETWORK.POLYGON]: 'https://polygonscan.com/',
  [NETWORK.PULSECHAIN]:
    'https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/',
  [NETWORK.SNOWTRACE]: 'https://snowscan.xyz/',
  [NETWORK.SOLANA]: 'https://solscan.io/',
  [NETWORK.TON]: 'https://tonscan.org/',
  [NETWORK.ATA]: '',
  [NETWORK.BLAST]: 'https://blastscan.io/',
  [NETWORK.LINEA]: 'https://lineascan.build/',
  [NETWORK.UNICHAIN]: 'https://uniscan.xyz/',
  [NETWORK.BERACHAIN]: 'https://berascan.com/',
  [NETWORK.SONIC]: 'https://sonicscan.org/',
  [NETWORK.METIS]: 'https://explorer.metis.io/',
  [NETWORK.TRON]: 'https://tronscan.org/#/',
  [NETWORK.ABSTRACT]: 'https://abscan.org/',
};

// @ts-ignore
export const NETWORK_SOURCE_TOKEN: {
  [networkName in NETWORK]: TokenShortArrayData;
} = {
  [NETWORK.ARBITRUM]: [
    'WETH',
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
  ],
  [NETWORK.BASE]: ['WETH', '0x4200000000000000000000000000000000000006', 18],
  [NETWORK.BINANCE]: ['WETH', '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', 18],
  [NETWORK.ETHEREUM]: [
    'WETH',
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
  ],
  [NETWORK.FANTOM]: [
    'AXLUSDC',
    '0x1b6382dbdea11d97f24495c9a90b7c88469134a4',
    6,
  ],
  [NETWORK.GNOSIS]: ['WETH', '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1', 18],
  [NETWORK.OPTIMISM]: [
    'WETH',
    '0x4200000000000000000000000000000000000006',
    18,
  ],
  [NETWORK.POLYGON]: ['WETH', '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 18],
  [NETWORK.PULSECHAIN]: ['WETH', '', 0],
  [NETWORK.SNOWTRACE]: [
    'WETH',
    '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    18,
  ],
  [NETWORK.SOLANA]: ['WETH', '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs', 8],
  [NETWORK.TON]: ['WETH', '', 0],
  [NETWORK.ATA]: ['ATA', '', 0],
  [NETWORK.BLAST]: ['WETH', '0x4300000000000000000000000000000000000004', 18],
  [NETWORK.LINEA]: ['WETH', '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f', 18],
  [NETWORK.UNICHAIN]: [
    'WETH',
    '0x4200000000000000000000000000000000000006',
    18,
  ],
  [NETWORK.BERACHAIN]: [
    'WETH',
    '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
    18,
  ],
  [NETWORK.SONIC]: ['WETH', '0x50c42dEAcD8Fc9773493ED674b675bE577f2634b', 18],
  [NETWORK.METIS]: ['WETH', '0x420000000000000000000000000000000000000a', 18],
  [NETWORK.TRON]: ['WETH', '', 18],
  [NETWORK.ABSTRACT]: [
    'WETH',
    '0x3439153EB7AF838Ad19d56E1571FBD09333C2809',
    18,
  ],
};

const baseDbTokenData = {
  active: true,
  automation: true,
  slippage: 0.01,
  comments: '',
  trackingValue: 1,
};

export const NETWORK_TO_ZRO_CHAIN_ID: { [network in NETWORK]?: number } = {
  [NETWORK.ETHEREUM]: 30101,
  [NETWORK.BINANCE]: 30102,
  [NETWORK.SNOWTRACE]: 30106,
  [NETWORK.POLYGON]: 30109,
  [NETWORK.ARBITRUM]: 30110,
  [NETWORK.OPTIMISM]: 30111,
  [NETWORK.BASE]: 30184,
  [NETWORK.SOLANA]: 30168,
  [NETWORK.SONIC]: 30332,
  [NETWORK.METIS]: 30151,
  [NETWORK.BLAST]: 30243,
  [NETWORK.FANTOM]: 30112,
  [NETWORK.GNOSIS]: 30145,
  [NETWORK.LINEA]: 30183,
  [NETWORK.ABSTRACT]: 30324,
  [NETWORK.BERACHAIN]: 30362,
  [NETWORK.UNICHAIN]: 30320,
  // @ts-ignore
  Mantle: 30181,
  // @ts-ignore
  Scroll: 30214,
};
