import React, { memo, ReactNode, useMemo, useState } from 'react';
import { Color } from 'react-bootstrap/esm/types';
import { createRoot, Root } from 'react-dom/client';
import { Badge, Button, Col, Modal, Row, Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import capitalize from 'lodash.capitalize';

import typing from '../assets/typing.gif';
import popa from '../assets/popa.gif';
import kekec from '../assets/kekec.gif';

import { SwalStyled } from './Popup';
import { getNetworkImage } from '../assets/networks';
import { TxPeerStatus, TxPeerType } from '../common/events/zmq';
import { ZMQPeer } from '../common/types';
import { NETWORK, NETWORK_EXPLORER_ADDRESS } from '../common/defaults';
import { API } from '../http';
import { useAppContext } from '../context/app.context';

type HeaderSectionProps = {
  justifyContent?: 'start' | 'end' | 'center';
  children?: ReactNode;
};
export const HeaderSection = memo(function HeaderSection(
  {
    children,
    justifyContent,
  }: HeaderSectionProps) {
  return (
    <Col
      className={
        'd-flex gap-2 ' + (justifyContent ? `justify-content-${justifyContent}` : '')
      }
    >
      {children}
    </Col>
  );
});

/**
 *
 */
type HeaderButtonProps = {
  img?: string;
  text: string | JSX.Element;
  hoverText?: string | JSX.Element;
  variant?: string /** [outline-]primary etc */;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};
const headerHoverStyle: any = {
  position: 'fixed',
  top: '44px',
  background: '#fff',
  padding: '8px',
  borderRadius: '4px',
  border: '1px solid #555',
};
export const HeaderButton = memo(function HeaderButton(
  {
    img,
    text,
    hoverText,
    variant,
    onClick,
  }: HeaderButtonProps) {
  const [hover, setHover] = useState(false);
  return (
    <Button
      style={{ display: 'flex', alignItems: 'center' }}
      size="sm"
      variant={variant || 'light'}
      disabled={!onClick}
      onClick={onClick}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {img && <img width="14px" src={img} alt="" style={{ marginRight: '4px' }} />}
      <span>{text}</span>
      {hover && hoverText && <div style={headerHoverStyle}>{hoverText}</div>}
    </Button>
  );
});

/**
 *
 */
type HeaderTxServiceButtonProps = {
  hoverText?: string | JSX.Element;
  variant?: string /** [outline-]primary etc */;
  peerStatus: TxPeerStatus;
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};
export const HeaderTxServiceButton = memo(function HeaderTxServiceButton(
  {
    hoverText,
    variant,
    peerStatus,
    text,
    onClick,
  }: HeaderTxServiceButtonProps) {
  const [hover, setHover] = useState(false);
  const hoverElement = useMemo(() => {
    if (hoverText) return hoverText;
    return text;
  }, [hoverText, hover]);
  return (
    <Button
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 0,
        background: 'transparent',
        border: 'none',
        width: '40px',
      }}
      onClick={onClick}
      disabled={!onClick}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1px',
          height: '80%',
          textAlign: 'center',
          width: '100%',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          backgroundColor: variant === 'success' ? '#157347' : '#a52834',
        }}
      >
        <span style={{
          fontSize: '0.875rem',
          width: '100%',
        }}>{text}</span>
        {hover && <div style={headerHoverStyle}>{hoverElement}</div>}
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center', height: '20%', width: '100%' }}
      >
        {Object.keys(peerStatus).map((peer) => (
          <div
            key={peer}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '1px',
              borderRadius: '4px',
              width: '100%',
              height: '100%',
              backgroundColor: peerStatus[peer as TxPeerType].connected
                ? '#157347'
                : '#a52834',
            }}
          />
        ))}
      </div>
    </Button>
  );
});

/**
 *
 */
type HeaderBadgeProps = {
  text?: string;
  textColor?: Color;
  bgColor?: Color;
  children?: ReactNode;
};
export const HeaderBadge = memo(function HeaderBadge(
  {
    text,
    textColor,
    bgColor,
    children,
  }: HeaderBadgeProps) {
  return (
    <Badge
      bg={bgColor}
      text={textColor}
      className="d-flex"
      style={{ alignItems: 'center' }}
    >
      <Stack direction="horizontal" gap={1}>
        {text}
        {children}
      </Stack>
    </Badge>
  );
});

export const DevAppend = memo(function DevAppend() {
  return <span style={{ color: 'red', fontWeight: 'bold' }}>DEV DB</span>;
});

export const BuildAppend = memo(function BuildAppend({
                                                       buildNumber,
                                                     }: {
  buildNumber?: string;
}) {
  return (
    <span style={{ color: '#adb5bd', fontSize: '0.7em', marginLeft: '10px' }}>
      {buildNumber ? '#' + buildNumber : ''}
    </span>
  );
});

export function getLogoSrc() {
  return kekec;
  if (import.meta.env.VITE_NODE_ENV === 'production') {
    return popa;
    // return stonks;
    // return gnat;
    // return unicorn
  }
  return typing;
}

/**
 *
 */
type HeaderProps = {
  children?: ReactNode;
};
export default memo(function Header_C({ children }: HeaderProps) {
  const { appState: { projectName, devMode } } = useAppContext();
  const append = devMode ? (
    <DevAppend />
  ) : (
    <BuildAppend
      buildNumber={import.meta.env.VITE_NODE_ENV === 'production' ? 'prod' : 'dev'} />
  );

  return (
    <Container fluid className="headerContainer">
      <Row>
        <Col className="sidebarOffset">
          <Container>
            <Link to="/" className="d-grid" style={{ textDecoration: 'none' }}>
              <Button
                className="text-start d-flex align-items-center gap-2"
                variant="light"
              >
                <img className={'logoImg'} src={getLogoSrc()}></img>
                {projectName || 'Title'}
                {append}
              </Button>
            </Link>
          </Container>
        </Col>
        <Col>
          <Container fluid>
            <Row>{children}</Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
});

function WorkersModal() {
  const { appState: { user } } = useAppContext();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button size="sm" variant="outline-primary" onClick={handleShow}>
        Worker
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Worker actions</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
          <Button
            onClick={() => {
              API.command({ to: 'worker', cmd: 'saveStats' });
              setTimeout(() => handleClose(), 250);
            }}
          >
            Save file
          </Button>
          <Button
            onClick={() => {
              API.command({ to: 'worker', cmd: 'clearBan' });
              setTimeout(() => handleClose(), 250);
            }}
          >
            Clear ban
          </Button>
          <Button
            onClick={() => {
              API.command({ to: 'worker', cmd: 'recreateClients' });
              setTimeout(() => handleClose(), 250);
            }}
          >
            Recreate clients
          </Button>
          <Button
            onClick={() => {
              API.command({ to: 'worker', cmd: 'requestNewWorker' });
              setTimeout(() => handleClose(), 250);
            }}
          >
            {'🚀'} Request new worker
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

/**
 *
 */
type HeaderStartStopSectionProps = {};
export const HeaderStartStopSection_C = memo(
  function HeaderStopStartSection({}: HeaderStartStopSectionProps) {
    const { appState: { user, brainState, miscSettings } } = useAppContext();
    const { workersRunning, routeSearchRunning, routeSearchProgress } = brainState;

    const handleUpdateMiscSettings = () => {
      if (miscSettings?.sellOnly === undefined) {
        return;
      }
      const updates = {
        sellOnly: !(miscSettings?.sellOnly ?? false),
      };
      API.updateMiscSettings({ updates });
    };

    if (workersRunning) {
      return (
        <>
          <HeaderButton
            text="Stop"
            variant="outline-danger"
            onClick={routeSearchRunning ? undefined : () => API.stopWorkers()}
          />
          <HeaderBadge bgColor="light" textColor="muted">
            <HeaderBadge text="working" bgColor="success" />
          </HeaderBadge>

          <HeaderButton
            text="Sell Only"
            variant={miscSettings?.sellOnly ? 'danger' : 'outline-secondary'}
            onClick={handleUpdateMiscSettings}
          />

          {/*<CallGirls />*/}
          <WorkersModal />
        </>
      );
    }

    return (
      <>
        <HeaderButton
          text="Start"
          variant="outline-success"
          onClick={routeSearchRunning ? undefined : () => API.startWorkers()}
        />
        <HeaderBadge bgColor="light" textColor="muted">
          <HeaderBadge text="stopped" bgColor="danger" />
        </HeaderBadge>

        {/*<CallGirls />*/}
      </>
    );
  },
);

export const CallGirls = () => {
  return {
    /* {user.role === "Admin" && <HeaderButton
            text="💃 Call girls"
            variant="outline-danger"
            onClick={() => {
            API.command({
                to: 'interface',
                cmd: 'call_girls',
              });
            }}
          />} */
  };
};

/**
 *
 */
type HeaderConnectedServicesSectionProps = {};
export const HeaderConnectedServicesSection_C = memo(
  function HeaderConnectedServicesSection({}: HeaderConnectedServicesSectionProps) {
    const {
      appState: {
        connectedServices,
        brainState: { txPeerStatus, zmqPeerStatus },
      },
    } = useAppContext();
    const entries = Object.entries(connectedServices).sort((a, b) => {
      if (a[0] === 'transactions') {
        return 1000;
      }
      return a[0].localeCompare(b[0]);
    });
    const { [TxPeerType.TX_CORE]: _, ...txPeers } = txPeerStatus ?? {};

    return (
      <>
        {entries.map(([serviceName, service]) => {
          const { numConnected, numExpected } = service;
          const serviceZMQStatus = zmqPeerStatus[serviceName] ?? {};
          const zmqHealthy = Object.values(serviceZMQStatus).every(s => s > 0);
          const variant = !zmqHealthy ? 'warning' : numConnected < numExpected ? 'danger' : 'success';

          const hoverCmp: JSX.Element = (
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {Object.keys(serviceZMQStatus).map((peer) => (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '140px',
                  marginBottom: '4px',
                  fontWeight: 'bold',
                  color: serviceZMQStatus[peer] > 0 ? '#07bc0c' : '#e74c3c',
                }} key={peer}>
                  <span style={{
                    marginRight: '4px',
                    width: '80px',
                    textAlign: 'left',
                  }}>{peer}</span>
                  <span
                    style={{ marginRight: '4px' }}>{serviceZMQStatus[peer] > 0 ? 'OK' : 'FAIL'}</span>
                  <span>{serviceZMQStatus[peer]}</span>
                </div>
              ))}
            </div>
          );

          if (serviceName === 'transactions') {
            return (
              <HeaderTxServiceButton
                key={serviceName}
                peerStatus={txPeers as any}
                hoverText={hoverCmp}
                text={`${serviceName[0]}: ${numConnected}`}
                variant={variant}
                onClick={() => openRestartTxServicePopup('Transactions', txPeerStatus)}
              />
            );
          }

          return (
            <HeaderButton
              key={serviceName}
              text={`${serviceName[0]}: ${numConnected}`}
              variant={variant}
              hoverText={hoverCmp}
              onClick={() => openRestartServicePopup(serviceName)}
            />
          );
        })}
      </>
    );
  },
);

export const openRestartServicePopup = async (serviceName: string) => {
  SwalStyled.fire({
    title: `Are you sure you want to restart ${serviceName}?`,
    html: '<div id="restart-service-form"></div>',
    showConfirmButton: false,
    showCloseButton: true,
    width: '600px',
    didOpen: () => {
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#restart-service-form',
      ) as HTMLElement;

      if (formContainer) {
        const root: Root = createRoot(formContainer);
        root.render(
          <RestartServiceForm
            serviceName={serviceName}
            onClose={() => SwalStyled.close()}
          />,
        );

        (SwalStyled as any)._restartServiceRoot = root;
      }
    },
    willClose: () => {
      // Clean up the React component when modal is closed
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#restart-service-form',
      ) as HTMLElement;

      if (formContainer && (SwalStyled as any)._restartServiceRoot) {
        const root: Root = (SwalStyled as any)._restartServiceRoot;
        root.unmount();
        delete (SwalStyled as any)._restartServiceRoot;
      }
    },
  });
};

const serviceNameDeploymentMap: Omit<Record<ZMQPeer, string>, 'brain'> = {
  [ZMQPeer.NODER]: 'noder-noder',
  [ZMQPeer.EVENTER]: 'eventer-eventer',
  [ZMQPeer.CRONER]: 'croner-croner',
  [ZMQPeer.WORKER]: 'worker-worker',
  [ZMQPeer.TRANSACTIONS]: 'transactions-transactions',
  [ZMQPeer.MONITORING]: 'monitoring-monitoring',
  [ZMQPeer.AGGREGATOR]: 'aggregator-aggregator',
};

interface RestartServiceProps {
  serviceName: string;
  onClose: () => void;
}

const RestartServiceForm: React.FC<RestartServiceProps> = ({ serviceName, onClose }) => {
  const handleRestart = () => {
    API.restartService(serviceName);
    onClose();
  };

  const handleRestartWithScaling = () => {
    const params = {
      deploymentName: (serviceNameDeploymentMap as any)[serviceName],
      namespace: serviceName === 'worker' ? 'worker' : 'core',
    };
    API.restartServiceWithScaling(serviceName, params);
    onClose();
  };

  return (
    <div style={{ marginTop: '24px' }}>
      <Button
        style={{ width: '160px', marginRight: '24px' }}
        variant="primary"
        onClick={handleRestart}
      >
        Restart
      </Button>
      <Button
        style={{ width: '160px', color: 'white' }}
        variant="warning"
        onClick={handleRestartWithScaling}
      >
        Restart By Scaling
      </Button>
    </div>
  );
};

const blockSnapshot: { [key in NETWORK]?: { value: string; timeUpdate: number } } = {};
const blockTimeDiffTreshold: { [key in NETWORK]?: number } & { default: number } = {
  [NETWORK.ETHEREUM]: 15_000,
  [NETWORK.ARBITRUM]: 5_000,
  [NETWORK.BASE]: 6_000,
  [NETWORK.BINANCE]: 6_000,
  [NETWORK.POLYGON]: 8_000,
  default: 40_000,
};

export function openRestartTxServicePopup(serviceName: string, peerStatus: TxPeerStatus) {
  SwalStyled.fire({
    title: `Restart Tx Services`,
    html: '<div id="restart-tx-services-form"></div>',
    showConfirmButton: false,
    showCloseButton: true,
    width: '600px',
    didOpen: () => {
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#restart-tx-services-form',
      ) as HTMLElement;

      if (formContainer) {
        // Create a root.
        const root: Root = createRoot(formContainer);

        // Render the React component inside the Swal modal
        root.render(
          <RestartTxServicesForm
            serviceName={serviceName}
            peerStatus={peerStatus}
            onClose={() => SwalStyled.close()}
          />,
        );

        // Store the root instance to unmount later
        (SwalStyled as any)._restartTxServicesRoot = root;
      }
    },
    willClose: () => {
      // Clean up the React component when modal is closed
      const modalContent = SwalStyled.getHtmlContainer();
      const formContainer = modalContent?.querySelector(
        '#restart-tx-services-form',
      ) as HTMLElement;

      if (formContainer && (SwalStyled as any)._restartTxServicesRoot) {
        const root: Root = (SwalStyled as any)._restartTxServicesRoot;
        root.unmount();
        delete (SwalStyled as any)._restartTxServicesRoot;
      }
    },
  });
}

interface RestartTxServicesFormProps {
  serviceName: string;
  peerStatus: TxPeerStatus;
  onClose: () => void;
}

const RestartTxServicesForm: React.FC<RestartTxServicesFormProps> = (
  {
    serviceName,
    peerStatus,
    onClose,
  }) => {
  const handleRestart = () => {
    API.restartService('transactions');
    onClose();
  };

  const handleRestartWithScaling = () => {
    const params = {
      deploymentName: 'tx-core',
      namespace: 'core',
    };
    API.restartServiceWithScaling(serviceName, params);
    onClose();
  };

  const handleRestartPeer = (peer: TxPeerType) => {
    API.restartTxPeer(peer);
    onClose();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '400px',
          marginTop: '24px',
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            marginBottom: '8px',
            marginRight: 'auto',
          }}
        >
          Peers
        </p>
        {Object.keys(peerStatus)
          .filter((peer) => peer !== TxPeerType.TX_CORE)
          .map((peer: string) => (
            <div
              key={peer}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8px',
                width: '100%',
              }}
            >
              <span>{capitalize(peer.replace('tx:', ''))}</span>
              <span
                style={{
                  height: '36px',
                  lineHeight: '40px',
                  fontWeight: 'bold',
                  color: peerStatus[peer as TxPeerType].connected ? '#157347' : '#a52834',
                }}
              >
                {peerStatus[peer as TxPeerType].connected ? 'CONNECTED' : 'DISCONNECTED'}
              </span>
              <Button
                style={{ height: '36px' }}
                onClick={() => handleRestartPeer(peer as TxPeerType)}
              >
                Restart
              </Button>
            </div>
          ))}
      </div>
      <div
        style={{
          marginTop: '72px',
          width: '400px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button
          style={{ width: '160px', marginRight: '24px' }}
          variant="primary"
          onClick={handleRestart}
        >
          Restart
        </Button>
        <Button
          style={{ width: '160px', color: 'white' }}
          variant="warning"
          onClick={handleRestartWithScaling}
        >
          Restart By Scaling
        </Button>
      </div>
    </div>
  );
};

/**
 *
 */
type HeaderBlockNumSectionProps = {};
export const HeaderBlockNumSection_C = memo(
  function HeaderBlockNumSection({}: HeaderBlockNumSectionProps) {
    const { appState: { newHeads } } = useAppContext();
    const networkNames = Object.keys(newHeads ?? {});

    return (
      <div className="d-flex gap-0 justify-content-end col">
        {networkNames
          .sort((a, b) => a.localeCompare(b))
          .map((_networkName) => {
            const networkName = _networkName as NETWORK;

            const bnString = (newHeads?.[networkName]?.num ?? -1).toString();
            const bnLocaleString = (newHeads?.[networkName]?.num ?? -1).toLocaleString();

            let msSinceLastUpd = 0;
            let tresholdValue =
              blockTimeDiffTreshold[networkName] || blockTimeDiffTreshold.default;

            const snapshotLastValue = blockSnapshot?.[networkName];
            if (!snapshotLastValue) {
              blockSnapshot[networkName] = {
                value: bnString,
                timeUpdate: Date.now(),
              };
            } else {
              if (snapshotLastValue.value !== bnString) {
                blockSnapshot[networkName] = {
                  value: bnString,
                  timeUpdate: Date.now(),
                };
              } else {
                msSinceLastUpd = Date.now() - snapshotLastValue.timeUpdate;
              }
            }

            let showWarning = false;
            if (
              tresholdValue !== undefined &&
              tresholdValue > 0 &&
              msSinceLastUpd > 0 &&
              msSinceLastUpd > tresholdValue
            ) {
              showWarning = true;
            }
            const timeDiffToDisplay = Math.floor(msSinceLastUpd / 1000);

            return (
              <HeaderButton
                key={networkName}
                img={getNetworkImage(networkName)}
                variant={showWarning ? 'danger' : undefined}
                text={
                  <>
                    <Stack
                      direction="vertical"
                      style={{ position: 'relative', fontVariantNumeric: 'tabular-nums' }}
                    >
                      <span>
                        {bnString.length > 2
                          ? bnString.slice(bnString.length - 2)
                          : bnString}
                      </span>
                      <span
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          fontSize: '0.6em',
                          marginTop: '-6px',
                        }}
                      >
                        {/* {goNoderBnString.length > 2
												? goNoderBnString.slice(goNoderBnString.length - 2)
												: goNoderBnString} */}
                        {`${timeDiffToDisplay}s`}
                      </span>
                    </Stack>
                  </>
                }
                hoverText={
                  <>
                    <Stack
                      direction="vertical"
                      style={{ position: 'relative', fontVariantNumeric: 'tabular-nums' }}
                    >
                      <span>{bnLocaleString}</span>
                      <span
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          fontSize: '0.6em',
                          marginTop: '-6px',
                        }}
                      >
                        {`${timeDiffToDisplay}s`}
                      </span>
                    </Stack>
                  </>
                }
                onClick={() => {
                  window.open(
                    `${NETWORK_EXPLORER_ADDRESS[networkName]}/block/${bnString}`,
                    '_blank',
                  );
                }}
              />
            );
          })}
      </div>
    );
  },
);
