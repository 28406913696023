import { Updater } from 'use-immer';
import React from 'react';
import BigNumber from 'bignumber.js';
import superjson from 'superjson';

import { BRAIN_EVENTS, WS_EVENTS, WS_EVENTS_PARAMS } from '../common/events/ws';
import Sound from '../modules/Sound';
import { addToast, errorToast, infoToast, successToast } from '../components/Toast';
import { getNetworkImage } from '../assets/networks';
import {
  NODER_EVENTS,
  TX_EVENTS,
  TxSentData,
  WORKER_EVENTS,
} from '../common/events/zmq';
import { DBTX, txErrCodeToMsg, TxErrorCode, TxType } from '../common/types';
import { marshal } from '../common/misc';
import { NETWORK } from '../common/defaults';
import { AppState } from '../context/app.context';
import { API } from '../http';

export const wsHandlers: { [key in WS_EVENTS]?: any } = {
  [WS_EVENTS.BR_INIT]: (setAppState: Updater<AppState>) => handleInitInterface.bind(null, setAppState),
  [WS_EVENTS.BR_EVENT_CACHE]: (setAppState: Updater<AppState>) => handleInterfaceEventCache.bind(null, setAppState),
  [WS_EVENTS.BR_BRAIN_STATE]: (setAppState: Updater<AppState>) => handleBrainStateUpdate.bind(null, setAppState),
  [WS_EVENTS.BR_AWS_UPDATE_DONE]: (setAppState: Updater<AppState>) => handleAWSGroupsUpdateDone.bind(null, setAppState),
  [WS_EVENTS.BR_CONNECTED_USERS]: (setAppState: Updater<AppState>) => handleConnectedUsers.bind(null, setAppState),
  [WS_EVENTS.BR_CONNECTED_SERVICES]: (setAppState: Updater<AppState>) => handleConnectedServices.bind(null, setAppState),
  [WS_EVENTS.BR_UPDATE_MISC_SETTINGS_DONE]: (setAppState: Updater<AppState>) => handleUpdateMiscSettingsDone.bind(null, setAppState),
  [WS_EVENTS.BR_STOP_RECONNECT]: (setAppState: Updater<AppState>) => handleStopReconnect,
  [WS_EVENTS.BR_NEW_USER_LOG]: (setAppState: Updater<AppState>) => handleUserLog.bind(null, setAppState),
  [WS_EVENTS.BR_COMMAND]: (setAppState: Updater<AppState>) => handleCommand,
  [WS_EVENTS.BR_NOTIFY]: (setAppState: Updater<AppState>) => handleNotify,
  [WS_EVENTS.BR_SOUND]: (setAppState: Updater<AppState>) => handleSound,
  [WS_EVENTS.BR_CREATE_TOKEN_DONE]: (setAppState: Updater<AppState>) => handleCreateTokenDone.bind(null, setAppState),
  [WS_EVENTS.BR_UPDATE_TOKEN_DONE]: (setAppState: Updater<AppState>) => handleUpdateTokenDone.bind(null, setAppState),
  [WS_EVENTS.BR_DELETE_TOKEN_DONE]: (setAppState: Updater<AppState>) => handleDeleteTokenDone.bind(null, setAppState),
  [WS_EVENTS.BR_CREATE_BRIDGE_DONE]: (setAppState: Updater<AppState>) => handleCreateBridgeDone.bind(null, setAppState),
  [WS_EVENTS.BR_UPDATE_BRIDGE_DONE]: (setAppState: Updater<AppState>) => handleUpdateBridgeDone.bind(null, setAppState),
  [WS_EVENTS.BR_DELETE_BRIDGE_DONE]: (setAppState: Updater<AppState>) => handleDeleteBridgeDone.bind(null, setAppState),
  [WS_EVENTS.BR_ADD_CHECKER_RES]: (setAppState: Updater<AppState>) => handleAddCheckerRes.bind(null, setAppState),
  [WS_EVENTS.BR_DEL_CHECKER_RES]: (setAppState: Updater<AppState>) => handleDeleteCheckerRes.bind(null, setAppState),
  [WS_EVENTS.ND_BLOCK]: (setAppState: Updater<AppState>) => handleNewHeads.bind(null, setAppState),
  [WS_EVENTS.ND_GAS]: (setAppState: Updater<AppState>) => handleGasEstimate.bind(null, setAppState),
  [WS_EVENTS.ND_BALANCE]: (setAppState: Updater<AppState>) => handleBalanceUpdate.bind(null, setAppState),
  [WS_EVENTS.ND_SECOND_BALANCE]: (setAppState: Updater<AppState>) => handleSecondaryBalanceUpdate.bind(null, setAppState),
  [WS_EVENTS.WR_ITER_RES]: (setAppState: Updater<AppState>) => handleWorkerIterationRes.bind(null, setAppState),
  [WS_EVENTS.WR_ITER_ERR]: (setAppState: Updater<AppState>) => handleWorkerIterationErr.bind(null, setAppState),
  [WS_EVENTS.WR_STATS]: (setAppState: Updater<AppState>) => handleWorkerStats.bind(null, setAppState),
  [WS_EVENTS.WR_ANALYTICS]: (setAppState: Updater<AppState>) => handleWorkerAnalytics.bind(null, setAppState),
  [WS_EVENTS.WR_EVENTS]: (setAppState: Updater<AppState>) => handleWorkerEvents.bind(null, setAppState),
  [WS_EVENTS.WR_LP_STAT]: (setAppState: Updater<AppState>) => handleWorkerLpStats.bind(null, setAppState),
  [WS_EVENTS.EV_STATS]: (setAppState: Updater<AppState>) => handleEventerStats.bind(null, setAppState),
  [WS_EVENTS.TX_SENT]: (setAppState: Updater<AppState>) => handleTxSent.bind(null, setAppState),
  [WS_EVENTS.TX_CONFIRMED]: (setAppState: Updater<AppState>) => handleTxConfirmed.bind(null, setAppState),
};

export const appWsEvents = Object.keys(wsHandlers) as WS_EVENTS[];

function handleInitInterface(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_INIT],
) {
  const {
    devMode,
    msRemainingBeforeDisconnect,
    allTokens,
    competitors,
    bridges,
    miscSettings,
    brainState,
    awsGroups,
    connectedServices,
  } = data;

  setAppState((draft) => {
    draft.devMode = devMode;
    draft.msRemainingBeforeDisconnect = msRemainingBeforeDisconnect;
    draft.allTokens = allTokens;
    draft.competitors = competitors;
    draft.bridges = bridges;
    draft.miscSettings = miscSettings;
    draft.brainState = brainState;
    draft.awsGroups = awsGroups;
    draft.connectedServices = connectedServices;
  });
}

function handleInterfaceEventCache(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_EVENT_CACHE],
) {
  const deserializedCache = superjson.deserialize(data as any) as any;
  setAppState((draft) => {
    for (const key of Object.keys(deserializedCache ?? {})) {
      // @ts-ignore
      draft[key] = deserializedCache[key];
    }
  });
}

function handleCommand({ cmd }: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_COMMAND]) {
  if (cmd === 'call_girls') {
    Sound.play('call');
    setTimeout(() => {
      document.getElementById('root')?.classList.add('girls-bg');
    }, 1000);
    setTimeout(() => {
      document.getElementById('root')?.classList.remove('girls-bg');
    }, 19000);
  }
}

function handleUserLog(
  setAppState: Updater<AppState>,
  userLog: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_NEW_USER_LOG],
) {
  setAppState((draft) => {
    if (!draft.userLogs.find((l) => l.id === userLog.id)) {
      draft.userLogs = draft.userLogs.concat([userLog]);
    }
  });
}

function handleStopReconnect() {
  localStorage.setItem('reconn', '0');
}

function handleBrainStateUpdate(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_BRAIN_STATE],
) {
  setAppState((draft) => {
    draft.brainState = data;
  });
}

function handleAWSGroupsUpdateDone(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_AWS_UPDATE_DONE],
) {
  setAppState((draft) => {
    draft.awsGroups = data.awsGroups;
  });
}

function handleConnectedUsers(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_CONNECTED_USERS],
) {
  setAppState((draft) => {
    draft.connectedUsers = data;
  });
}

function handleConnectedServices(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_CONNECTED_SERVICES],
) {
  setAppState((draft) => {
    draft.connectedServices = data;
  });
}

function handleUpdateMiscSettingsDone(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_UPDATE_MISC_SETTINGS_DONE],
) {
  setAppState((draft) => {
    draft.miscSettings = data.miscSettings;
  });
}

function handleNewHeads(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[NODER_EVENTS.ND_BLOCK],
) {
  setAppState((draft) => {
    draft.newHeads[data.network as keyof AppState['newHeads']] = data;
  });
}

function handleGasEstimate(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[NODER_EVENTS.ND_GAS],
) {
  setAppState((draft) => {
    draft.gasEstimate[data.network as NETWORK] = data;
  });
}

function handleBalanceUpdate(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[NODER_EVENTS.ND_BALANCE],
) {
  setAppState((draft) => {
    draft.balance[data.network as NETWORK] = data.balance;
  });
}

function handleSecondaryBalanceUpdate(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[NODER_EVENTS.ND_SECOND_BALANCE],
) {
  setAppState((draft) => {
    draft.secondaryBalance[data.address] = {
      ...(draft.secondaryBalance[data.address] || {}),
      [data.network]: data.balance,
    };
  });
}

function handleWorkerIterationRes(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[WORKER_EVENTS.WR_ITER_RES],
) {
  setAppState((draft) => {
    const deserialized = superjson.deserialize(data as never as any) as any;
    for (const [tokenName, res] of Object.entries(deserialized)) {
      draft.trackingResults[tokenName as any] = res as any;
    }
  });
}

function handleWorkerIterationErr(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[WORKER_EVENTS.WR_ITER_ERR],
) {
  setAppState((draft) => {
    for (const [tokenName, res] of Object.entries(data)) {
      draft.trackingErrors[tokenName] = res;
    }
  });
}

function handleEventerStats(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[WORKER_EVENTS.WR_EVENTS],
) {
  setAppState((draft) => {
    draft.eventerStatsString = data;
  });
}

function handleWorkerStats(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[WORKER_EVENTS.WR_STATS],
) {
  setAppState((draft) => {
    draft.workerStatsString = data;
  });
}

function handleWorkerAnalytics(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[WORKER_EVENTS.WR_ANALYTICS],
) {
  setAppState((draft) => {
    draft.workerAnalyticsString = data;
  });
}

function handleWorkerEvents(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[WORKER_EVENTS.WR_EVENTS],
) {
  setAppState((draft) => {
    draft.workerEventsString = data;
  });
}

function handleWorkerLpStats(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[WORKER_EVENTS.WR_LP_STAT],
) {
  setAppState((draft) => {
    draft.workerLpStatString = data;
  });
}

function handleNotify(data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_NOTIFY]) {
  if (data.autoClose === 60000) {
    const f = async () => {
      const num = 14 + Math.floor(Math.random() * 8);
      for (let i = 0; i < num; i++) {
        Sound.play('dindilin');
        await new Promise((r) => setTimeout(r, 50));
      }
    };
    f();
  }
  addToast(data.msg, { type: data.type, autoClose: data.autoClose });
}

function handleSound(data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_SOUND]) {
  Sound.play(data.name as any);
}

function handleCreateTokenDone(
  setAppState: Updater<AppState>,
  { tokenName, tokenData }: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_CREATE_TOKEN_DONE],
) {
  setAppState((appState) => {
    appState.allTokens[tokenName] = tokenData;
  });
}

function handleUpdateTokenDone(
  setAppState: Updater<AppState>,
  { tokenName, tokenData }: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_UPDATE_TOKEN_DONE],
) {
  // toastDataUpdate(`Token ${tokenName} updated`)
  setAppState((appState) => {
    appState.allTokens[tokenName] = tokenData;
  });
}

function handleDeleteTokenDone(
  setAppState: Updater<AppState>,
  { tokenName }: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_DELETE_TOKEN_DONE],
) {
  setAppState((appState) => {
    delete appState.allTokens[tokenName];
  });
}

function handleCreateBridgeDone(
  setAppState: Updater<AppState>,
  { bridgeName, bridgeData }: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_CREATE_BRIDGE_DONE],
) {
  setAppState((appState) => {
    appState.bridges[bridgeName] = bridgeData;
  });
}

function handleUpdateBridgeDone(
  setAppState: Updater<AppState>,
  { bridgeName, bridgeData }: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_UPDATE_BRIDGE_DONE],
) {
  setAppState((appState) => {
    appState.bridges[bridgeName] = bridgeData;
  });
}

function handleDeleteBridgeDone(
  setAppState: Updater<AppState>,
  { bridgeName, updatedTokens }: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_DELETE_BRIDGE_DONE],
) {
  setAppState((appState) => {
    delete appState.bridges[bridgeName];
    appState.allTokens = {
      ...appState.allTokens,
      ...updatedTokens,
    };
  });
}

function handleTxSent(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[TX_EVENTS.TX_SENT],
) {
  Sound.play('dindilin');
  infoToast(() => <TxInfoToastContent data={data} state="SENDING" />, {
    autoClose: 10000,
    icon: false,
  });
}

function handleTxConfirmed(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[TX_EVENTS.TX_CONFIRMED],
) {
  Sound.play('beep');
  setTimeout(() => {
    Sound.play('beep');
  }, 200);

  for (const txHash in data.txs) {
    const tx = data.txs[txHash];
    const toastData = {
      tokenName: tx.tokenName,
      txTypes: [tx.txType],
      bridgeName: tx.bridgeName,
      networkBuy: tx.networkBuy,
      networkSell: tx.networkSell,
    };
    const content = tx.success ? (
      <TxInfoToastContent data={toastData} state="CONFIRM" success={true} />
    ) : (
      <TxInfoToastContent data={toastData} state="FAIL" success={false} />
    );

    const method = tx.success ? successToast : errorToast;
    method(() => content, { autoClose: 10000, icon: false });

    if (
      !tx.success ||
      (tx.txType !== TxType.Sell) ||
      BigNumber(tx.expectedProfit ?? 0).isLessThan(BigNumber(0.5))
    ) {
      continue;
    }
    checkActualProfit(tx.tradeId, tx);
  }

  if (!data.errCode) {
    return;
  }

  const txsData = Object.values(data.txs ?? {});
  if (!txsData?.length) {
    return;
  }

  const txData = txsData[0];
  const toastData = {
    tokenName: txData.tokenName,
    txTypes: [txData.txType],
    bridgeName: txData.bridgeName,
    networkBuy: txData.networkBuy,
    networkSell: txData.networkSell,
  };
  const errCode = data.errCode;
  errorToast(() => <TradeFailToastContent data={toastData} errCode={errCode} />, {
    autoClose: 10000,
    icon: false,
  });
}

function handleAddCheckerRes(
  setAppState: Updater<AppState>,
  data: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_ADD_CHECKER_RES],
) {
  setAppState((appState) => {
    appState.checkerRes = { ...appState.checkerRes, [data.iterId]: data };
  });
}

function handleDeleteCheckerRes(
  setAppState: Updater<AppState>,
  iterId: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_DEL_CHECKER_RES],
) {
  setAppState((appState) => {
    delete appState.checkerRes[iterId];
    appState.checkerRes = { ...appState.checkerRes };
  });
}

async function checkActualProfit(tradeId: string, sellTx: DBTX) {
  try {
    const tradeRes = await API.getTrade(tradeId);
    if (!tradeRes?.data) {
      return;
    }
    const trade = tradeRes.data;

    const buyTx = Object.values(trade.txs).find(
      (tx: DBTX) => tx.txType === TxType.Swap || tx.txType === TxType.Bridge,
    );
    if (!buyTx) {
      return;
    }

    const amountIn = BigNumber(buyTx.srcTokenAmount ?? '0');
    if (amountIn.isNaN() || amountIn.isZero()) {
      return;
    }
    const amountOut = BigNumber(sellTx.dstTokenAmount ?? '0');
    if (amountOut.isNaN() || amountOut.isZero()) {
      return;
    }
    const diff = amountOut.minus(amountIn);
    if (diff.isGreaterThan(BigNumber(0.5))) {
      Sound.play('profit');
    }
  } catch (err: any) {
    console.error(marshal(err));
  }
}

type TxInfoToastContentProps = {
  data: TxSentData;
  state: 'SENDING' | 'CONFIRM' | 'FAIL';
  success?: boolean;
};
const TxInfoToastContent = ({ data, state, success }: TxInfoToastContentProps) => {
  const stateColor = state === 'SENDING' ? '#3498db' : success ? '#07bc0c' : '#e74c3c';

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span
        style={{
          fontWeight: 600,
          marginBottom: '8px',
        }}
      >
        <span style={{ marginRight: '4px' }}>
          {data?.tokenName} ({data?.txTypes.join(', ')}):
        </span>
        <span style={{ color: stateColor }}>{state}</span>
      </span>
      <TxInfoContent data={data ?? {}} />
    </div>
  );
};

type TradeFailToastContentProps = {
  data: TxSentData;
  errCode: TxErrorCode;
};
const TradeFailToastContent = ({ data, errCode }: TradeFailToastContentProps) => {
  const errMsg = txErrCodeToMsg[errCode];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span
        style={{
          fontWeight: 600,
          marginBottom: '8px',
        }}
      >
        <span style={{ marginRight: '4px' }}>{data?.tokenName}:</span>
        <span style={{ color: '#e74c3c' }}>{errMsg}</span>
      </span>
      <TxInfoContent data={data ?? {}} />
    </div>
  );
};

type TxInfoContentProps = {
  data: TxSentData;
};
const TxInfoContent = ({ data }: TxInfoContentProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        width="20px"
        height="20px"
        style={{ marginRight: '4px' }}
        src={getNetworkImage(data.networkBuy)}
      />
      {data.bridgeName}
      <img
        width="20px"
        height="20px"
        style={{ marginLeft: '4px', marginRight: '4px' }}
        src={getNetworkImage(data.networkSell)}
      />
    </div>
  );
};
