import {
  BuildApprove,
  BuildTransfer,
  LockedNonces,
  Sell,
  Trade,
  TxType,
  WorkerIterationResult,
} from '../../types';
import { BRIDGE, NETWORK } from '../../defaults';
import { PinType } from './brain';

export type UpdatePinEventData = {
  tradeId: string;
  tokenName: string;
  networkBuy: NETWORK;
  networkSell: NETWORK;
  value: boolean;
  type: PinType;
};

export type TxSentData = {
  tokenName: string;
  txTypes: TxType[];
  bridgeName: BRIDGE;
  networkBuy: NETWORK;
  networkSell: NETWORK;
}

export type TxPeerStatusItem = {
  connected: boolean;
  lastPing: number;
};

export enum TxPeerType {
  TX_CORE = 'tx:core',
  TX_BRIDGE = 'tx:bridge',
}

export type TxPeerStatus = Record<TxPeerType, TxPeerStatusItem>;

/**
 *
 */
export enum TX_EVENTS {
  TX_TRADE_CREATED = 'TX_TRADE_CREATED',
  TX_SENT = 'TX_SENT',
  TX_CREATED = 'TX_CREATED',
  TX_CONFIRMED = 'TX_CONFIRMED',
  TX_TRADE_COMPLETED = 'TX_TRADE_COMPLETED',
  TX_CREATE_TRANSFER = 'TX_CREATE_TRANSFER',
  TX_CREATE_SWAP_APPROVE = 'TX_CREATE_SWAP_APPROVE',
  TX_CREATE_BRIDGE_APPROVE = 'TX_CREATE_BRIDGE_APPROVE',
  TX_FORCE_BUY = 'TX_FORCE_BUY',
  TX_SET_PIN = 'TX_SET_PIN',
  TX_SET_SELL_PIN = 'TX_SET_SELL_PIN',
  TX_LOCKED_NONCES = 'TX_LOCKED_NONCES',
  TX_UPDATE_SELL = 'TX_UPDATE_SELL',
  TX_PEER_STATUS = 'TX_PEER_STATUS',
  TX_RESTART_PEER = 'TX_RESTART_PEER',
}

/**
 *
 */
export type TRANSACTIONS_EVENTS_PARAMS = {
  [TX_EVENTS.TX_TRADE_CREATED]: Partial<Trade>;
  [TX_EVENTS.TX_SENT]: TxSentData;
  [TX_EVENTS.TX_CREATED]: Partial<Trade>;
  [TX_EVENTS.TX_CONFIRMED]: Partial<Trade>;
  [TX_EVENTS.TX_TRADE_COMPLETED]: Partial<Trade>;
  [TX_EVENTS.TX_CREATE_TRANSFER]: BuildTransfer;
  [TX_EVENTS.TX_CREATE_SWAP_APPROVE]: BuildApprove;
  [TX_EVENTS.TX_CREATE_BRIDGE_APPROVE]: BuildApprove;
  [TX_EVENTS.TX_FORCE_BUY]: WorkerIterationResult;
  [TX_EVENTS.TX_SET_PIN]: UpdatePinEventData;
  [TX_EVENTS.TX_SET_SELL_PIN]: UpdatePinEventData;
  [TX_EVENTS.TX_LOCKED_NONCES]: LockedNonces;
  [TX_EVENTS.TX_UPDATE_SELL]: Sell;
  [TX_EVENTS.TX_PEER_STATUS]: TxPeerStatus;
  [TX_EVENTS.TX_RESTART_PEER]: { peer: TxPeerType };
};

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
  [Property in TX_EVENTS]: TRANSACTIONS_EVENTS_PARAMS[Property];
};
