import { MODULE_EVENTS, MODULE_EVENTS_PARAMS } from '../events/zmq';

export enum ZMQPeer {
  BRAIN = 'brain',
  NODER = 'noder',
  TRANSACTIONS = 'transactions',
  WORKER = 'worker',
  EVENTER = 'eventer',
  CRONER = 'croner',
  MONITORING = 'monitoring',
  AGGREGATOR = 'aggregator',
}

export type ZMQPeerPort = number;
export type ZMQPeerAddress = `tcp://${string}:${ZMQPeerPort}`;

export type ZMQSubPeerInfo = {
  name: ZMQPeer;
  address: ZMQPeerAddress;
};

export type ZMQSubs = Partial<Record<ZMQPeer, ZMQPeerAddress[]>>;

export type ZMQPubs = Partial<Record<ZMQPeer, ZMQPeerPort[]>>;

export type ZMQEvent<T extends MODULE_EVENTS> = {
  type: T;
  data: MODULE_EVENTS_PARAMS[T];
};

export type ZMQEventHandler<T extends MODULE_EVENTS> = (
  ev: MODULE_EVENTS_PARAMS[T],
) => void | Promise<void>;

export type ZMQEventRouting = Partial<Record<MODULE_EVENTS, ZMQPeer[]>>;

export type ZMQEventRoutingConf = Partial<
  Record<MODULE_EVENTS, ZMQSubPeerInfo[]>
>;

export type ZMQEventSubscriptions = {
  [K in MODULE_EVENTS]?: ZMQEventHandler<K>;
};

export type ZMQConf = {
  name: ZMQPeer; // current service peer name
  subs: ZMQSubs; // peers we listen for events from
  pubs: ZMQPubs; // peers we send events to
};
