import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './App.scss';
import { AppProvider } from './context/app.context';
import { AuthProvider } from './context/auth.context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AppProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </AppProvider>
  </React.StrictMode>,
);
