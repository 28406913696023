import {BigNumber} from "bignumber.js";
import util from "util";

import {NETWORK} from "../defaults";
import {DBToken, DBTokens, WorkerIterationResult} from "../types";

/**
 *
 */
export function filterActiveTokens(tokensData: DBTokens) {
  return Object.fromEntries(
    Object.entries(tokensData).filter(([tokenName, tokenData]) => {
      return tokenData.active;
    }),
  );
}

/**
 *
 */
export function applySlippageToBigIntForOutput(
  val: bigint,
  slippage: number,
): bigint {
  const fixedMultiplier = BigInt(Math.round(slippage * 100));
  const intermediateResult = val * fixedMultiplier;
  return val - (intermediateResult + BigInt(100 / 2)) / BigInt(100);
}

/**
 *
 */
export function applySlippageToBigIntForInput(
  val: bigint,
  slippage: number,
): bigint {
  const fixedMultiplier = BigInt(Math.round(slippage * 100));
  const intermediateResult = val * fixedMultiplier;
  return val + (intermediateResult + BigInt(100 / 2)) / BigInt(100);
}

export function getNetworksFromIterationResult(res: WorkerIterationResult) {
  const { networkA, networkB } = res;
  const networkBuy = Object.keys(res.networkResults)[0] as NETWORK;
  const networkSell = networkA === networkBuy ? networkB : networkA;
  return { networkBuy, networkSell };
}

/**
 * Network order does not matter.
 */
export function getBridgeNamesByTokenNetworkPairSortedByPriority(
  tokenBridge: DBToken["bridge"],
  networkA: NETWORK,
  networkB: NETWORK,
) {
  const directionalNetworkKey = `_${networkA}-${networkB}`;
  const allTokenBridgeNames = Object.keys(tokenBridge ?? {});
  const bridgeNamesWithThisNetworkPairAndActive = allTokenBridgeNames.filter(
    (bridgeName) => {
      const networksInBridge = tokenBridge?.[bridgeName]?.networks ?? [];
      return (
        (networksInBridge.includes(directionalNetworkKey as NETWORK) ||
          (networksInBridge.includes(networkA) &&
            networksInBridge.includes(networkB))) &&
        tokenBridge?.[bridgeName]?.automation === true
      );
    },
  );
  return bridgeNamesWithThisNetworkPairAndActive.sort((a, b) => {
    return (
      ((tokenBridge || {})[a]?.priority ?? 0) -
      ((tokenBridge || {})[b]?.priority ?? 0)
    );
  });
}

/**
 *
 */
export function getTokenNetworkTrackIgnore(
  networkTrackIgnore: DBToken["networkTrackIgnore"],
  networkCheck: NETWORK,
): boolean {
  if (!networkTrackIgnore) return false;
  return networkTrackIgnore[networkCheck] === true;
}

/**
 *
 */
export function getTokenNetworkPairPoopAutomation(
  networkPairPoopAutomation: DBToken["networkPairPoopAutomation"],
  networkA: NETWORK,
  networkB: NETWORK,
): boolean | NETWORK {
  if (!networkPairPoopAutomation) return false;
  const [n1, n2] = [networkA, networkB].sort((a, b) => a.localeCompare(b)) as [
    NETWORK,
    NETWORK,
  ];
  const value = networkPairPoopAutomation[`${n1}_${n2}`];
  return value === undefined || value === null ? false : value;
}

/**
 *
 */
export function marshal(data?: any): string {
  if (data === undefined || data === null) return "";

  const inspected = util.inspect(data, {
    depth: 5,
    maxArrayLength: null,
    maxStringLength: null,
  });
  const singleLine = inspected.replace(/\n\s*/g, " ");
  return JSON.stringify(singleLine);
}

export function toMilliseconds(timestamp: number): number {
  if (timestamp < 1_000_000_000_000) {
    return timestamp * 1000;
  }
  return timestamp;
}

export function sanitizeDecimalStr(decStr: string, maxDecimals = 18) {
  // Split the number string into the integer part and the decimal part
  let [integerPart, decimalPart] = decStr.split(".");

  // If there's no decimal part or if it's already within the limit, return the original string
  if (!decimalPart || decimalPart.length <= maxDecimals) {
    return decStr;
  }

  // Trim the decimal part to the maximum allowed length
  decimalPart = decimalPart.slice(0, maxDecimals);

  // Recombine the integer part and the trimmed decimal part
  return `${integerPart}.${decimalPart}`;
}

export function numberFromHex(hexString: string): string {
  if (!hexString.startsWith("0x")) hexString = "0x" + hexString;

  const decimalValue = BigInt(hexString);
  return decimalValue.toString(10);
}

export function txDecimalValue(value?: string): string {
  if (!value) return "0";

  let decimalValue;

  if (value.startsWith("0x")) {
    decimalValue = BigNumber(numberFromHex(value)).div(1e18);
  } else {
    // If the value length is greater than 10 and does not contain a decimal point,
    // assume it's in wei and needs to be converted to Ether by dividing by 1e18.
    if (value.length > 10 && !value.includes(".")) {
      decimalValue = BigNumber(value).div(1e18); // Convert Wei to Ether
    } else {
      return value;
    }
  }

  return decimalValue.toPrecision(6).replace(/\.?0+$/, "");
}

export function txWeiValue(value?: string): string {
  if (!value) return "0";

  if (value.startsWith("0x")) {
    return BigNumber(numberFromHex(value))
      .integerValue(BigNumber.ROUND_DOWN)
      .toFixed();
  }
  if (value.includes(".")) {
    return BigNumber(value)
      .multipliedBy(1e18)
      .integerValue(BigNumber.ROUND_DOWN)
      .toFixed();
  }
  return value;
}

export const wait = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));
