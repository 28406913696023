import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './components/Loader';
import { WS } from './ws';
import { CloseAllToast } from './components/Toast';
import { useAppContext } from './context/app.context';
import { AppRouter } from './router/AppRouter';
import { useAuth } from './context/auth.context';

export default function App() {
  const { appState, setAppState } = useAppContext();
  const { userAuthenticated } = useAuth();

  useEffect(() => {
    const wsUrl = import.meta.env.VITE_SOCKET_BASE_URL || 'ws://localhost:3000';
    WS.init(wsUrl);
    console.log(`ws initialized with ${wsUrl}`);
  }, []);

  useEffect(() => {
    if (!userAuthenticated) return;
    WS.connect(setAppState).then(() => console.log(`ws connected`));
  }, [userAuthenticated]);

  return <>
    <Loader projectName="DefiTracker 4" buildNumber="0.0.1" />
    <div style={{ position: 'relative' }}>
      <ToastContainer
        position="top-right"
        closeButton={false}
        newestOnTop
        closeOnClick
        pauseOnHover
      />
      <CloseAllToast />
    </div>
    <AppRouter appState={appState} setAppState={setAppState} />
  </>;
}
