import { createContext, useContext, useEffect, useState } from 'react';
import { Updater } from 'use-immer';
import { decodeToken } from 'react-jwt';

import { AppState, useAppContext } from './app.context';
import SecureLocalStorage from '../modules/SecureLocalStorage';
import { API } from '../http';

type AuthContextType = {
  userAuthenticated: boolean;
  setUserAuthenticated: (auth: boolean) => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode, }) => {
  const { appState, setAppState } = useAppContext();
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  useAppUser({ appState, setAppState });

  useEffect(() => {
    const apiKey = SecureLocalStorage.getItem('USER_API_KEY');
    if (!apiKey) {
      setUserAuthenticated(false);
      return;
    }

    const user = decodeToken(apiKey) as any;
    setAppState(draft => {
      draft.user = user;
    });
    setUserAuthenticated(true);
    API.apiKey = apiKey;
  }, []);

  return (
    <AuthContext.Provider value={{ userAuthenticated, setUserAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export type UseAppUserProps = {
  appState: AppState;
  setAppState: Updater<AppState>
}

const useAppUser = ({ appState, setAppState }: UseAppUserProps) => {
  useEffect(() => {
    let interval: any;

    if (appState.msRemainingBeforeDisconnect > 0) {

      interval = setInterval(() => {
        fetch(window.location.origin)
          .then(() => {
            // console.log('🟢 ping to keep alive success');
          })
          .catch((e) => {
            // console.warn('🟠 ping to keep alive error', e);
          });
      }, 1000 * 60);
    }

    return () => {
      clearInterval(interval);
    };
  }, [appState.msRemainingBeforeDisconnect]);

  useEffect(() => {
    let dateWhenExpires = new Date(Date.now() + appState.msRemainingBeforeDisconnect);

    const interval = setInterval(() => {
      let msRemaining = dateWhenExpires.getTime() - Date.now();
      let minutes = Math.floor(msRemaining / 1000 / 60);
      let seconds = Math.floor((msRemaining / 1000) % 60);

      if (minutes < 0) minutes = 0;
      if (seconds < 0) seconds = 0;

      setAppState((draft) => {
        draft.timeValueBeforeDisconnect = `${String(minutes).padStart(2, '0')}:${String(
          seconds,
        ).padStart(2, '0')}`;

        if (minutes < 5) {
          draft.disconnectButtonVariant = 'danger';
        } else if (minutes < 10) {
          draft.disconnectButtonVariant = 'warning';
        } else {
          draft.disconnectButtonVariant = 'light';
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [appState.msRemainingBeforeDisconnect]);
};
