import { BRAIN_EVENTS, BRAIN_EVENTS_PARAMS } from './brain';
import { EVENTER_EVENTS, EVENTER_EVENTS_PARAMS } from './eventer';
import { CRONER_EVENTS, CRONER_EVENTS_PARAMS } from './croner';
import { NODER_EVENTS, NODER_EVENTS_PARAMS } from './noder';
import { TRANSACTIONS_EVENTS_PARAMS, TX_EVENTS } from './tx';
import { WORKER_EVENTS, WORKER_EVENTS_PARAMS } from './worker';

export * from './brain';
export * from './eventer';
export * from './croner';
export * from './noder';
export * from './tx';
export * from './worker';

export enum SYSTEM_EVENTS {
  PING = 'PING',
}

export type SYSTEM_EVENTS_PARAMS = {
  [SYSTEM_EVENTS.PING]: { serviceName: string, timestamp: number }
}

/**
 * Merge event enums
 */
export const MODULE_EVENTS = {
  ...SYSTEM_EVENTS,
  ...BRAIN_EVENTS,
  ...EVENTER_EVENTS,
  ...CRONER_EVENTS,
  ...NODER_EVENTS,
  ...TX_EVENTS,
  ...WORKER_EVENTS,
};

/**
 * Merge event params types
 */
export type MODULE_EVENTS_PARAMS = SYSTEM_EVENTS_PARAMS
  & EVENTER_EVENTS_PARAMS
  & CRONER_EVENTS_PARAMS
  & BRAIN_EVENTS_PARAMS
  & NODER_EVENTS_PARAMS
  & TRANSACTIONS_EVENTS_PARAMS
  & WORKER_EVENTS_PARAMS;

/**
 * It is needed. Please keep it like it is
 */
export type MODULE_EVENTS = keyof MODULE_EVENTS_PARAMS;
