import { BRAIN_EVENTS, BRAIN_EVENTS_PARAMS } from './brain';

export * from './brain';

export const WS_EVENTS = {
  ...BRAIN_EVENTS,
};

export type WS_EVENTS_PARAMS = BRAIN_EVENTS_PARAMS;

export type WS_EVENTS = keyof WS_EVENTS_PARAMS;
