import { BRIDGE, NETWORK, REQUESTER } from '../defaults';

export type Hex = `0x${string}`;

export enum TxType {
  ApproveSwap = 'ApproveSwap',
  ApproveSell = 'ApproveSell',
  ApproveBridge = 'ApproveBridge',
  Swap = 'Swap',
  Bridge = 'Bridge',
  Claim = 'Claim',
  Sell = 'Sell',
  TransferNative = 'TransferNative',
  TransferToken = 'TransferToken',
  TransferFrom = 'TransferFrom',
}

export enum TxSide {
  BUY = 'buy',
  SELL = 'sell',
}

export type DBTX = {
  // after tx creation
  tradeId: string;
  iterId: string;
  txType: TxType;
  side: TxSide;

  bribeWei?: string;
  bribeSOL?: string;
  bribeUSD?: string;
  sendMethod: string;

  networkBuy: NETWORK;
  networkSell: NETWORK;

  sender: string;
  tokenName: string;
  tokenAddress: string;
  txHash: string;
  txVersion: 0 | 2;
  provider: string;
  nonce: number;
  contractAddress?: string;
  inputData?: string;
  value?: string;

  bridgeName: BRIDGE;
  bridgeAmount?: string;
  bridgeApproveTarget?: string;
  dexName?: string;

  blockStart: number;
  blockStartTime?: number;
  blockSubmitted: number;
  blockSubmittedTime?: number;
  blockTarget: number;
  blockTargetTime?: number;
  blockConfirmed?: number;
  blockConfirmedTime?: number;
  blockPos?: number;

  timeSent: number;
  timeCreated: number;
  timeStart?: number;

  srcTokenAmount?: string;
  dstTokenAmount?: string;
  expectedProfit?: string;

  gasPrice?: string;
  gasLimit?: string;
  maxFeePerGas?: string;
  maxPriorityFeePerGas?: string;

  success?: boolean;
  errMessage?: string;
  replaced?: boolean;
  isManual?: boolean;
  sendBack?: boolean;

  // after tx confirmation
  timeConfirmed?: number;

  fee?: string;
  effectiveGasPrice?: string;
  gasUsed?: string;

  nativePrice?: number;
  ethPrice?: number;
};

export type MergedTxHistory = { [key: string]: EncodedTx };

export type TradeTxs = { [txHash: string]: DBTX };

export type Trade = {
  id: string;
  startTime: number; // time when trade started
  completionTime: number; // time when trade completed (not including sell)
  txs: TradeTxs;
  mergedTxHistory: MergedTxHistory;
  lastUpdatedBy: 'croner' | 'transactions';
  errCode?: TxErrorCode;
  sellFailed?: boolean;
  profitInfo?: TradeProfitInfo;
};

export type Trades = {
  [tradeId: string]: Trade;
};

export type TradesCountInfo = {
  total24H: number;
  total7D: number;
  total30D: number;
};

export type TradesProfitInfo = {
  id: 'main';
  profit24H: string;
  profit7D: string;
  profit30D: string;
  fee24H: string;
  fee7D: string;
  fee30D: string;
  count: TradesCountInfo;
};

export type TradeProfitInfo = {
  msg: string;
  profit?: number;
  fee: number;
  color: '#28a745' | '#6c757d' | '#900916' | '#dc3545';
};

export type TxsAPIResponse = {
  trades: Trade[];
  profitInfo: TradesProfitInfo;
  total: number;
};

export type EncodedTx = {
  type: number;
  from: string;
  to: string;
  value: string;
  data: string;
  nonce?: number;
  gasLimit?: bigint;
  maxFeePerGas?: bigint;
  gasPrice?: bigint;
  maxPriorityFeePerGas?: bigint;
  chainId?: number;
};

export type TxFee = {
  effectiveGasPrice?: string;
  gasUsed?: string;
  fee: string;
};

export type SellEvent = {
  networkBuy: NETWORK;
  networkSell: NETWORK;
  tokenName: string;
};

export enum SELL_STATUS {
  CREATED = 'created',
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
  STALE = 'stale',
  SKIP = 'skip',
  SENT_BACK = 'sent-back',
}

export type BridgeCtx = {
  [key: string]: any;
};

export type Sell = {
  tradeId: string;
  iterId: string;
  aggregatorType: REQUESTER;
  profit: string;
  blockNumber: number;
  timeCreated: number;

  bridge: BRIDGE;
  bridgeImplemented: boolean;
  bridgeApproveTarget?: string;
  bridgeTxHash: string;
  bridgeSlippage: number | null;
  bridgeCtx?: BridgeCtx;

  networkBuy: NETWORK;
  networkSell: NETWORK;
  networkSendBack?: NETWORK;

  amount: string;
  amountShifted: string;

  token: TokenInfo;
  balance: string;

  sendBack?: boolean;
  isClaimable: boolean;
  isTokensToContract: boolean;
  skipSell?: boolean;
  claimStatus: SELL_STATUS;
  sellStatus: SELL_STATUS;

  expectedAmountOut?: number;
  aggregatorAmountOut?: number;
  maxAmountIn?: number;
  minAmountOut: number;
  transferRemainderTo?: Hex;

  isForce: boolean;
  isTestTrade: boolean;
  bribe?: number;
  gasPriceGwei?: number;
};

export type BuildTransfer = {
  network: NETWORK;
  amount: string;
  tokenName: string;
};

export type BuildApprove = {
  tradeId?: string;
  txType: TxType.ApproveSwap | TxType.ApproveSell | TxType.ApproveBridge;
  targetAddr: string;
  tokenName: string;
  tokenAddr: string;
  network: NETWORK;
};

export type BridgeData = {
  bridgeName: BRIDGE;
  addresses: { [network in NETWORK]?: string };
  tokens?: { [tokenName: string]: { [network in NETWORK]?: string } };
};

export type DBBridgeData = { [name in BRIDGE]: BridgeData };

export type WorkerSwapTx = {
  from: string;
  to: string;
  data: string;
  value: string;
  gas: number;
  gasPrice: string;
};

export type TokenInfo = {
  tokenName: string;
  tokenDecim: number;
  tokenBuyAddr: string;
  tokenSellAddr: string;
  expensiveBribe?: boolean;
  cheapBribe?: boolean;
  customBribe?: string;
};

export type Allowance = {
  [tokenName: string]: AllowanceToken;
};

export type AllowanceToken = {
  tokenName: string;
  data: { [network in NETWORK]: AllowanceNetwork };
};

export type AllowanceNetwork = {
  address: string;
  targets: AllowanceTargets;
};

export type AllowanceTargets = {
  [address: string]: boolean;
};

export type LockedNonces = {
  [address: string]: {
    [network in NETWORK]?: number | null;
  };
};

export type ResendWithGasIncreaseRes = {
  txs: EncodedTx[];
  hashes: Hex[];
};

export type NodeReceipt = {
  hash: string;
  timestamp: number;
  blockNumber: number;
  index: number;
  nonce: number;
  status: string;
  gasUsed: string;
  gasPrice: string;
  logs: any[];
};

export type AssembleRes = {
  ctx: any;
  reasonCode?: AssembleFailReason;
  reason?: string;
  err?: string;
};

export enum AssembleFailReason {
  AUTOMATION_DISABLED,
  ITERATION_NOT_ALLOWED,
  SWAP_TX_EMPTY,
  SWAP_TX_EMPTY_COEF,
  TOKEN_PINNED,
  TOKEN_NO_TRADE,
  ARB_BRIDGE_NOT_ALLOWED,
  WRONG_BRIDGE_PROXY_ID,
}

export type SwapActionParams = {
  tokenIn: string;
  tokenOut: string;
  amountIn: bigint;
  approveTarget: string;
  to: string;
  value: bigint;
  data: string;
  poolCheckTarget?: string;
  poolCheckData?: string;
};

export type BaseBridgeActionParams = {
  proxyId: number;
  to: string;
  value: string;
  dataParams: string[];
  selector: string;
  checkerValue?: string;
  approveTarget?: string;
};

export type BridgeActionParams = BaseBridgeActionParams & {
  tokenIn: string;
  tokenOut: string;
  amountIn: bigint;
};

export enum TxErrorCode {
  GENERAL_ZMQ,
  GENERAL_UNIX,
  GENERAL_START_SERVICE,
  GENERAL_SERVICE_UNHANDLED,
  GENERAL_SERVICE_UNCAUGHT,
  GENERAL_TOKEN_NOT_FOUND,
  GENERAL_TRADE_NOT_FOUND,
  GENERAL_TX_NOT_FOUND,
  GENERAL_DB,
  GENERAL_INIT,
  GENERAL_PIN,
  CHECKER,
  CHECKER_RES,
  CHECKER_BALANCE_DIFF,
  CHECKER_SELL_FEE,
  CHECKER_SELL_BRIBE,
  CHECKER_ENCODED_TX,
  CONTROLLER_BASE_PUB_TX,
  CONTROLLER_BASE_OPTIMISTIC_APPROVE,
  CONTROLLER_BUY_PROCESS,
  CONTROLLER_BUY_FORCE,
  CONTROLLER_BUY_ITERATION_FOR_RETRY,
  CONTROLLER_BUY_RETRY,
  CONTROLLER_BUY_REGISTER_SELL,
  CONTROLLER_SELL_SELL,
  CONTROLLER_SELL_CLAIM,
  CONTROLLER_SELL_SEND_BACK,
  CONTROLLER_SELL_TRANSFER,
  CONTROLLER_SELL_RETRY,
  CONTROLLER_SELL_FANTOM,
  CONTROLLER_SELL_ADD_TRANSFER_FUNDS,
  CONTROLLER_SELL_ADD_TRANSFER_REMAINDER,
  CONTROLLER_SELL_WATCHER,
  CONTROLLER_MANUAL_TRANSFER,
  CONTROLLER_MANUAL_APPROVE,
  CONTROLLER_PARALLEL_PROCESS,
  CONTROLLER_PARALLEL_SELL_ALL,
  CONTROLLER_PARALLEL_SELL_PARTIAL,
  AGGREGATOR_SELL,
  BRIBE_CONF,
  BRIBE_TOKEN_CONF,
  BRIBE_WEI,
  BRIBE_LAMPORTS,
  PARSER_MERGED,
  PARSER_EVM_RECEIPT,
  PARSER_SOL_RECEIPT,
  RECEIPT_EVM_HANDLE_LOG,
  RECEIPT_EVM_CLEAR_CACHE,
  SWAP_PREPARATION,
  SENDER_EVM_MULTI_CREATE_CTX,
  SENDER_EVM_MULTI_PARSE,
  SENDER_EVM_CORE_PROCESS,
  SENDER_EVM_CORE_SIGN_SEND,
  SENDER_EVM_CORE_RESEND_GAS_INCREASE,
  SENDER_EVM_CORE_PROPAGATION,
  SENDER_SOL_SEND_BUNDLE,
  SENDDER_SOL_BUY,
  SENDDER_SOL_SELL,
  SENDDER_SOL_CLAIM,
  SENDDER_SOL_SELL_TO_CLAIM,
  SENDDER_SOL_SEND_BACK,
  SENDDER_SOL_PREPARE_SWAP,
  SENDDER_SOL_PREPARE_BRIDGE,
  RECEIPT_SOL_WAIT,
  RECEIPT_SOL_GENERATE_ERR,
  EVM_SEND_BLOCKRAZOR,
  EVM_SEND_BLOCKSMITH,
  EVM_SEND_CLUB48,
  EVM_SEND_FLASHBOTS,
  BUILDER_V1_MERGE,
  BUILDER_V2_MERGE,
  BUILDER_BRIDGE,
  BUILDER_SEND_BACK,
  BUILDER_ADJUST,
  BUILDER_PREPARE_SWAP,
  BUILDER_PREPARE_SEND_BACK,
  BUILDER_AGGREGATOR,
  BUILDER_SWAP,
  BUILDER_SWAP_WITH_SLIPPAGE,
  BUILDER_SLIPPAGE_FROM_BRIBE,
  BRIDGE_TX,
  BRIDGE_ACTION_PARAMS,
  BRIDGE_SOL_TX,
  BRIDGE_CLAIM,
  BRIDGE_SOL_CLAIM,
  CTX_CREATE,
  CTX_BUY_PREPARE_BASE_SELL,
  CTX_BUY_PREPARE_SELL,
  CTX_BUY_TOKEN_FOR_SELL,
  CTX_SELL_INIT_BALANCE,
  CTX_SELL_FIX_BALANCE,
  NODE_TOKEN_BALANCE,
  NODE_ALLOWANCE,
  SIMULATION_TENDERLY,
  TRADE_BUFFER,
}

export const txErrCodeToMsg: { [key in TxErrorCode]: string } = {
  [TxErrorCode.GENERAL_ZMQ]: 'zmq connection or communication failed',
  [TxErrorCode.GENERAL_UNIX]: 'unix socket connection or communication failed',
  [TxErrorCode.GENERAL_START_SERVICE]: 'failed to start service',
  [TxErrorCode.GENERAL_SERVICE_UNHANDLED]: 'service unhandled rejection',
  [TxErrorCode.GENERAL_SERVICE_UNCAUGHT]: 'service uncaught exception',
  [TxErrorCode.GENERAL_TOKEN_NOT_FOUND]: 'token not found',
  [TxErrorCode.GENERAL_TRADE_NOT_FOUND]: 'trade not found',
  [TxErrorCode.GENERAL_TX_NOT_FOUND]: 'tx not found',
  [TxErrorCode.GENERAL_DB]: 'db operation failed',
  [TxErrorCode.GENERAL_INIT]: 'init failed',
  [TxErrorCode.GENERAL_PIN]: 'pin logic failed',
  [TxErrorCode.CHECKER]: 'failed to check buy trade',
  [TxErrorCode.CHECKER_RES]: 'failed to prepare buy trade result in checker',
  [TxErrorCode.CHECKER_BALANCE_DIFF]:
    'failed to calculate balance diff in checker',
  [TxErrorCode.CHECKER_SELL_FEE]: 'failed to calculate sell fee in checker',
  [TxErrorCode.CHECKER_SELL_BRIBE]: 'failed to calculate sell bribe in checker',
  [TxErrorCode.CHECKER_ENCODED_TX]: 'failed to create encoded tx in checker',
  [TxErrorCode.CONTROLLER_BASE_PUB_TX]: 'pub tx preparation failed',
  [TxErrorCode.CONTROLLER_BASE_OPTIMISTIC_APPROVE]: 'optimistic approve failed',
  [TxErrorCode.CONTROLLER_BUY_PROCESS]: 'buy process failed',
  [TxErrorCode.CONTROLLER_BUY_FORCE]: 'failed to force trigger tx',
  [TxErrorCode.CONTROLLER_BUY_RETRY]: 'retry failed',
  [TxErrorCode.CONTROLLER_BUY_ITERATION_FOR_RETRY]:
    'failed to get lp iteration for retry from worker',
  [TxErrorCode.CONTROLLER_BUY_REGISTER_SELL]:
    'failed to register sell data in db',
  [TxErrorCode.CONTROLLER_SELL_SELL]: 'failed to perform sell',
  [TxErrorCode.CONTROLLER_SELL_CLAIM]: 'failed to perform claim',
  [TxErrorCode.CONTROLLER_SELL_SEND_BACK]: 'failed to perform send-back',
  [TxErrorCode.CONTROLLER_SELL_TRANSFER]: 'failed to perform transfer tx',
  [TxErrorCode.CONTROLLER_SELL_FANTOM]:
    'failed to send fantom to optimism usdc',
  [TxErrorCode.CONTROLLER_SELL_RETRY]: 'failed to perform sell retry',
  [TxErrorCode.CONTROLLER_SELL_ADD_TRANSFER_FUNDS]:
    'failed to add transfer funds tx',
  [TxErrorCode.CONTROLLER_SELL_ADD_TRANSFER_REMAINDER]:
    'failed to add transfer remainder tx',
  [TxErrorCode.CONTROLLER_SELL_WATCHER]: 'sell watcher failed',
  [TxErrorCode.CONTROLLER_MANUAL_TRANSFER]:
    'failed to perform manual transfer tx',
  [TxErrorCode.CONTROLLER_MANUAL_APPROVE]:
    'failed to perform manual approve tx',
  [TxErrorCode.CONTROLLER_PARALLEL_PROCESS]:
    'failed to perform parallel arbitrage tx',
  [TxErrorCode.CONTROLLER_PARALLEL_SELL_ALL]:
    'parallel arbitrage with sell all flow failed',
  [TxErrorCode.CONTROLLER_PARALLEL_SELL_PARTIAL]:
    'parallel arbitrage with sell partial flow failed',
  [TxErrorCode.AGGREGATOR_SELL]: 'failed to get aggregator result for sell tx',
  [TxErrorCode.BRIBE_WEI]: 'failed to calculate bribe',
  [TxErrorCode.BRIBE_CONF]: 'failed to find bribe config',
  [TxErrorCode.BRIBE_TOKEN_CONF]: 'failed to find token bribe config',
  [TxErrorCode.BRIBE_LAMPORTS]: 'failed to calculate jito tip',
  [TxErrorCode.PARSER_MERGED]: 'failed to parse merged tx',
  [TxErrorCode.PARSER_EVM_RECEIPT]: 'failed to parse evm receipt',
  [TxErrorCode.PARSER_SOL_RECEIPT]: 'failed to parse solana receipt',
  [TxErrorCode.RECEIPT_EVM_HANDLE_LOG]: 'failed to handle eventer log',
  [TxErrorCode.RECEIPT_EVM_CLEAR_CACHE]: 'failed to clear eventer logs cache',
  [TxErrorCode.SWAP_PREPARATION]: 'failed to prepare swap transaction',
  [TxErrorCode.SENDER_EVM_MULTI_CREATE_CTX]:
    'failed to perform multi send of tx',
  [TxErrorCode.SENDER_EVM_MULTI_PARSE]: 'failed to parse multi send receipts',
  [TxErrorCode.SENDER_EVM_CORE_PROCESS]: 'failed to process tx in sender',
  [TxErrorCode.SENDER_EVM_CORE_SIGN_SEND]: 'failed to sign and send tx',
  [TxErrorCode.SENDER_EVM_CORE_RESEND_GAS_INCREASE]:
    'failed to resend with gas increase',
  [TxErrorCode.SENDER_EVM_CORE_PROPAGATION]: 'failed to send with propagation',
  [TxErrorCode.SENDER_SOL_SEND_BUNDLE]: 'failed to send bundle to jito',
  [TxErrorCode.SENDDER_SOL_BUY]: 'failed to perform buy tx',
  [TxErrorCode.SENDDER_SOL_SELL]: 'failed to perform sell tx',
  [TxErrorCode.SENDDER_SOL_CLAIM]: 'failed to perform claim tx',
  [TxErrorCode.SENDDER_SOL_SELL_TO_CLAIM]:
    'failed to add sell tx to claim flow',
  [TxErrorCode.SENDDER_SOL_SEND_BACK]: 'failed to perform send-back tx',
  [TxErrorCode.SENDDER_SOL_PREPARE_SWAP]: 'failed to prepare solana swap data',
  [TxErrorCode.SENDDER_SOL_PREPARE_BRIDGE]:
    'failed to prepare solana bridge data',
  [TxErrorCode.RECEIPT_SOL_WAIT]: 'failed to fetch receipt for solana tx',
  [TxErrorCode.RECEIPT_SOL_GENERATE_ERR]:
    'failed to generate error for failed solana tx',
  [TxErrorCode.EVM_SEND_BLOCKRAZOR]: 'failed to send tx with blockrazor',
  [TxErrorCode.EVM_SEND_BLOCKSMITH]: 'failed to send tx with blocksmith',
  [TxErrorCode.EVM_SEND_CLUB48]: 'failed to send tx with club48',
  [TxErrorCode.EVM_SEND_FLASHBOTS]: 'failed to send tx with flashbots',
  [TxErrorCode.BUILDER_V1_MERGE]: 'failed to merge txs in the builder v1',
  [TxErrorCode.BUILDER_V2_MERGE]: 'failed to merge txs in the builder v2',
  [TxErrorCode.BUILDER_BRIDGE]: 'failed to encode bridge data in builder',
  [TxErrorCode.BUILDER_SEND_BACK]: 'failed to encode send-back data in builder',
  [TxErrorCode.BUILDER_ADJUST]:
    'failed to adjust gas and nonce for tx in builder',
  [TxErrorCode.BUILDER_PREPARE_SWAP]: 'failed to prepare swap data in builder',
  [TxErrorCode.BUILDER_PREPARE_SEND_BACK]:
    'failed to prepare send-back data in builder',
  [TxErrorCode.BUILDER_AGGREGATOR]: 'failed to create aggregator tx in builder',
  [TxErrorCode.BUILDER_SWAP]: 'failed to create swap tx in builder',
  [TxErrorCode.BUILDER_SWAP_WITH_SLIPPAGE]:
    'failed to create swap data with bribe slippage in builder',
  [TxErrorCode.BUILDER_SLIPPAGE_FROM_BRIBE]:
    'failed to calculate slippage from bribe',
  [TxErrorCode.BRIDGE_TX]: 'failed to prepare bridge tx',
  [TxErrorCode.BRIDGE_ACTION_PARAMS]:
    'failed to prepare bridge action parameters',
  [TxErrorCode.BRIDGE_SOL_TX]: 'failed to prepare solana bridge tx',
  [TxErrorCode.BRIDGE_CLAIM]: 'failed to prepare claim parameters',
  [TxErrorCode.BRIDGE_SOL_CLAIM]: 'failed to prepare solana claim parameters',
  [TxErrorCode.CTX_CREATE]: 'failed to create context',
  [TxErrorCode.CTX_BUY_PREPARE_BASE_SELL]:
    'failed to prepare base sell data after buy flow',
  [TxErrorCode.CTX_BUY_PREPARE_SELL]:
    'failed to prepare sell data after buy flow',
  [TxErrorCode.CTX_BUY_TOKEN_FOR_SELL]:
    'failed to get token for sell after buy flow',
  [TxErrorCode.CTX_SELL_INIT_BALANCE]:
    'failed to init sell balance from buy tx receipt',
  [TxErrorCode.CTX_SELL_FIX_BALANCE]:
    'failed to fix sell balance from buy tx receipt',
  [TxErrorCode.NODE_TOKEN_BALANCE]: 'chain request to get balance failed',
  [TxErrorCode.NODE_ALLOWANCE]: 'chain request to get allowance failed',
  [TxErrorCode.SIMULATION_TENDERLY]: 'failed to perform tenderly simulation',
  [TxErrorCode.TRADE_BUFFER]: 'trade buffer failed',
};
