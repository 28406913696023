import {
  AWSGroupScheme,
  BuildApprove,
  BuildTransfer,
  DBBridge,
  DBMiscSettings,
  DBToken,
  TxType,
} from '../../types';

export const API_KEY_HEADER = 'x-api-key';

export type UpdateAWSGroupsRequest = {
  awsGroups: AWSGroupScheme[];
};

export type CreateBridgeRequest = {
  bridgeName: string;
};

export type UpdateBridgeRequest = {
  bridgeName: string;
  updates: Partial<DBBridge>;
};

export type GetConfigResponse = {
  configName: string;
  configData: string;
};

export type GetConfigRequest = GetConfigResponse;

export type RestartServiceWithScalingRequest = {
  deploymentName: string;
  namespace: string;
};

export type UpdateMiscSettingsRequest = {
  updates: Partial<DBMiscSettings>;
};

export type ToggleBridgeAutomationRequest = {
  bridgeName: string;
  newValue: boolean;
};

export type CreateTokenRequest = {
  tokenName: string;
};

export type UpdateTokenRequest = {
  tokenName: string;
  updates: Partial<DBToken>;
};

export type LoadMoreLogsRequest = { from: number; limit: number };

export type TriggerManualTx = {
  type: TxType;
  params: BuildApprove | BuildTransfer;
};

export type CommandRequest = {
  to: string;
  cmd: any;
}

export type AccessKeyHeaderData = {
  iv: string;
  key: string;
  securedData: string;
};
