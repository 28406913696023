import React, { Suspense, useDeferredValue } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Updater } from 'use-immer';

import { AppState } from '../context/app.context';
import { useAuth } from '../context/auth.context';

// Lazy-loaded page components
const Dashboard = React.lazy(() => import('../pages/dashboard'));
const Transactions = React.lazy(() => import('../pages/transactions'));
const LatestTransactions = React.lazy(() => import('../pages/transactions/latest'));
const TSettings = React.lazy(() => import('../pages/tsettings'));
const TSettingsTokens = React.lazy(() => import('../pages/tsettings/tokens'));
const NewTransaction = React.lazy(() => import('../pages/transactions/new'));
const Settings = React.lazy(() => import('../pages/settings'));
const SettingsAWS = React.lazy(() => import('../pages/settings/aws'));
const SettingsConfigs = React.lazy(() => import('../pages/settings/configs'));
const SettingsTokens = React.lazy(() => import('../pages/settings/tokens'));
const Userlogs = React.lazy(() => import('../pages/settings/userlogs'));
const SettingsBridges = React.lazy(() => import('../pages/settings/bridges'));
const Login = React.lazy(() => import('../pages/login'));

export type AppRouterProps = {
  appState: AppState;
  setAppState: Updater<AppState>
};

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { userAuthenticated } = useAuth();
  return userAuthenticated ? children : <Navigate to="/login" replace />;
};

export const AppRouter = ({ appState, setAppState }: AppRouterProps) => {
  const { userAuthenticated } = useAuth();
  const deferredTrackingResults = useDeferredValue(appState.trackingResults);
  const deferredTrackingErrors = useDeferredValue(appState.trackingErrors);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard
                  workersRunning={appState.brainState.workersRunning}
                  workersStartedBy={appState.brainState.workersStartedBy}
                  pinnedTokens={appState.brainState.pinnedTokens}
                  autosellPins={appState.brainState.autosellPins}
                  wethPriceUSD={appState.brainState.wethPriceUSD}
                  nativePriceUSD={appState.brainState.nativePriceUSD}
                  newHeads={appState.newHeads}
                  allTokens={appState.allTokens}
                  bridges={appState.bridges}
                  trackingResults={deferredTrackingResults}
                  trackingErrors={deferredTrackingErrors}
                  selectedAccount={appState.user}
                  miscSettings={appState.miscSettings}
                  connectedUsers={appState.connectedUsers}
                  gasEstimate={appState.gasEstimate}
                  balance={appState.balance}
                  secondaryBalance={appState.secondaryBalance}
                  setAppState={setAppState}
                  checkerRes={appState.checkerRes}
                />
              </PrivateRoute>
            }
          />

          <Route
            path="/tx"
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />

          <Route
            path="/tx/latest"
            element={
              <PrivateRoute>
                <LatestTransactions user={appState.user} tokens={appState.allTokens} />
              </PrivateRoute>
            }
          />

          <Route
            path="/tsettings"
            element={
              <PrivateRoute>
                <TSettings />
              </PrivateRoute>
            }
          />

          <Route
            path="/tsettings/tokens"
            element={
              <PrivateRoute>
                <TSettingsTokens
                  allTokens={appState.allTokens}
                  bridges={appState.bridges}
                  miscSettings={appState.miscSettings}
                />
              </PrivateRoute>
            }
          />

          {appState.user?.role === 'Admin' && (
            <>
              <Route
                path="/tx/new"
                element={
                  <PrivateRoute>
                    <NewTransaction tokens={appState.allTokens} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />

              <Route
                path="/settings/aws"
                element={
                  <PrivateRoute>
                    <SettingsAWS awsGroups={appState.awsGroups} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/settings/configs"
                element={
                  <PrivateRoute>
                    <SettingsConfigs setAppState={setAppState} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/settings/tokens"
                element={
                  <PrivateRoute>
                    <SettingsTokens
                      allTokens={appState.allTokens}
                      bridges={appState.bridges}
                      miscSettings={appState.miscSettings}
                    />
                  </PrivateRoute>
                }
              />

              <Route
                path="/settings/userlogs"
                element={
                  <PrivateRoute>
                    <Userlogs userLogs={appState.userLogs} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/settings/bridges"
                element={
                  <PrivateRoute>
                    <SettingsBridges allTokens={appState.allTokens}
                                     bridges={appState.bridges} />
                  </PrivateRoute>
                }
              />
            </>
          )}

          <Route path="*"
                 element={<Navigate to={userAuthenticated ? '/' : '/login'} replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
