import { WS_EVENTS } from '../common/events/ws';
import WS from './client';

declare global {
  interface Window {
    pingInterval: any | undefined;
  }
}

clearInterval(window.pingInterval);
window.pingInterval = setInterval(() => {
  WS.emit(WS_EVENTS.BR_PING, {});
}, 4500);

export { WS };
