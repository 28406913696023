import { createContext, useContext } from 'react';
import { Updater, useImmer } from 'use-immer';

import { DEFAULT_BRAIN_STATE } from '../common/types/brain';
import { BRAIN_EVENTS, WS_EVENTS_PARAMS } from '../common/events/ws';
import {
  AWSGroupScheme,
  DBBridges,
  DBMiscSettings,
  DBTokens,
  UserLog,
} from '../common/types';
import { NETWORK } from '../common/defaults';
import { Competitors } from '../common/types/monitoring';
import { CheckerRes } from '../common/events/zmq';

export type AppState = {
  projectName: string;
  devMode: boolean;
  msRemainingBeforeDisconnect: number;
  timeValueBeforeDisconnect: string;
  disconnectButtonVariant: 'light' | 'warning' | 'danger';
  brainState: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_BRAIN_STATE];
  connectedServices: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_CONNECTED_SERVICES];

  userLogs: UserLog[];

  newHeads: { [key in NETWORK]?: WS_EVENTS_PARAMS[BRAIN_EVENTS.ND_BLOCK] };
  gasEstimate: { [key in NETWORK]?: WS_EVENTS_PARAMS[BRAIN_EVENTS.ND_GAS] };
  balance: {
    [networkName in NETWORK]?: {
      [tokenName: string]: string;
    };
  };
  secondaryBalance: {
    [walletAddress: string]: {
      [networkName in NETWORK]?: {
        [tokenName: string]: string;
      };
    };
  };

  allTokens: DBTokens;
  // newTokens: UnknownTokens
  competitors: Competitors;
  archivedTxsFileNames: string[];
  currentArchiveFileName: string | undefined;
  archivedTxs: { [key: string]: string };
  bridges: DBBridges;
  awsGroups: AWSGroupScheme[];
  trackingResults: WS_EVENTS_PARAMS[BRAIN_EVENTS.WR_ITER_RES];
  trackingErrors: WS_EVENTS_PARAMS[BRAIN_EVENTS.WR_ITER_ERR];
  eventerStatsString: string;
  workerStatsString: string;
  workerAnalyticsString: string;
  workerEventsString: string;
  workerLpStatString: string;

  miscSettings?: DBMiscSettings;
  connectedUsers: WS_EVENTS_PARAMS[BRAIN_EVENTS.BR_CONNECTED_USERS];

  user: {
    name: string;
    role: string;
  };

  checkerRes: CheckerRes;
};

export const defaultAppState: AppState = {
  projectName: 'DefiTracker 4',
  devMode: false,
  msRemainingBeforeDisconnect: 60 * 60 * 1000 - 1000,
  timeValueBeforeDisconnect: '59:59',
  disconnectButtonVariant: 'light',
  brainState: DEFAULT_BRAIN_STATE,
  connectedServices: {},

  userLogs: [],

  newHeads: {},
  gasEstimate: {},
  balance: {},
  secondaryBalance: {},

  allTokens: {},
  competitors: {},
  archivedTxsFileNames: [],
  currentArchiveFileName: undefined,
  archivedTxs: {},
  bridges: {},
  awsGroups: [],
  trackingResults: {},
  trackingErrors: {},
  eventerStatsString: '',
  workerStatsString: '',
  workerAnalyticsString: '',
  workerEventsString: '',
  workerLpStatString: '',

  miscSettings: undefined,
  connectedUsers: {},

  user: {
    name: '',
    role: '',
  },
  checkerRes: {},
};

export type AppContextType = {
  val: AppState;
  set: Updater<AppState>;
}

export const AppContext = createContext<AppContextType>({
  val: defaultAppState,
  set: () => undefined as any,
});

export type AppExpiryContextType = {
  timeValueBeforeDisconnect: AppState['timeValueBeforeDisconnect'];
  disconnectButtonVariant: AppState['disconnectButtonVariant'];
};
export const AppExpiryContext = createContext<AppExpiryContextType>({
  timeValueBeforeDisconnect: '59:59',
  disconnectButtonVariant: 'light',
});

export const AppProvider = ({ children }: { children: React.ReactNode, }) => {
  const [appState, setAppState] = useImmer<AppState>(defaultAppState);

  return <AppContext.Provider value={{ val: appState, set: setAppState }}>
    <AppExpiryContext.Provider
      value={{
        timeValueBeforeDisconnect: appState.timeValueBeforeDisconnect,
        disconnectButtonVariant: appState.disconnectButtonVariant,
      }}
    >
      {children}
    </AppExpiryContext.Provider>
  </AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return { appState: context.val, setAppState: context.set };
};
