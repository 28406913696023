import axios, { AxiosInstance, AxiosResponse } from 'axios';

import {
  API_KEY_HEADER,
  CommandRequest,
  CreateBridgeRequest,
  CreateTokenRequest,
  GetConfigRequest,
  LoadMoreLogsRequest,
  RestartServiceWithScalingRequest,
  ToggleBridgeAutomationRequest,
  TriggerManualTx,
  UpdateAWSGroupsRequest,
  UpdateBridgeRequest,
  UpdateMiscSettingsRequest,
  UpdateTokenRequest,
} from '../common/events/http';
import { TxPeerType, UpdatePinEventData } from '../common/events/zmq';
import { Sell, Trade, UserLog, WorkerIterationResult } from '../common/types';

const apiClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000/api',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});
apiClient.interceptors.request.use(async (req) => {
  if (!API.apiKey) {
    return req;
  }
  req.headers.set(API_KEY_HEADER, API.apiKey);
  return req;
});

class ApiService {
  private client: AxiosInstance;
  private _apiKey: string | null = null;

  constructor() {
    this.client = apiClient;

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        console.error('API Error:', error.response?.data || error.message);
        return Promise.reject(error);
      }
    );
  }

  set apiKey(key: string | null) {
    this._apiKey = key;
  }

  get apiKey() {
    return this._apiKey;
  }

  // Login
  async login(accessKey: string): Promise<
    AxiosResponse<{
      apiKey: string;
      user: { name: string; role: string };
    }>
  > {
    return this.client.post('/login', { accessKey });
  }

  // AWS Endpoints
  async restartAWSFormation(): Promise<AxiosResponse<void>> {
    return this.client.post('/aws/restart');
  }

  async updateAWSGroups(data: UpdateAWSGroupsRequest): Promise<AxiosResponse<void>> {
    return this.client.put('/aws/groups', data);
  }

  // Bridges Endpoints
  async createBridge(data: CreateBridgeRequest): Promise<AxiosResponse<void>> {
    return this.client.post('/bridges', data);
  }

  async updateBridge(data: UpdateBridgeRequest): Promise<AxiosResponse<void>> {
    return this.client.put(`/bridges/${data.bridgeName}`, data);
  }

  async deleteBridge(name: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/bridges/${name}`);
  }

  // Command Endpoint
  async command(data: CommandRequest): Promise<AxiosResponse<void>> {
    return this.client.post('/command', data);
  }

  // Configs Endpoints
  async getConfigs(): Promise<AxiosResponse<{ configNames: string[] }>> {
    return this.client.get('/configs');
  }

  async getConfig(name: string): Promise<AxiosResponse<GetConfigRequest>> {
    return this.client.get(`/configs/${name}`);
  }

  async updateConfig(data: GetConfigRequest): Promise<AxiosResponse<GetConfigRequest>> {
    return this.client.put(`/configs/${data.configName}`, data);
  }

  // Logs Endpoints
  async loadMoreLogs(data: LoadMoreLogsRequest): Promise<AxiosResponse<UserLog[]>> {
    return this.client.post('/logs/more', data);
  }

  // Services Endpoints
  async restartService(name: string): Promise<AxiosResponse<void>> {
    return this.client.post(`/services/${name}/restart`);
  }

  async restartServiceWithScaling(
    name: string,
    data: RestartServiceWithScalingRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post(`/services/${name}/restart/scaling`, data);
  }

  // Settings Endpoints
  async updateMiscSettings(
    data: UpdateMiscSettingsRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.put('/settings/misc', data);
  }

  async toggleBridgeAutomation(
    data: ToggleBridgeAutomationRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.put('/settings/automation/bridges', data);
  }

  async clearCacheResults(): Promise<AxiosResponse<void>> {
    return this.client.post('/settings/cache/results/clear');
  }

  // Tokens Endpoints
  async createToken(data: CreateTokenRequest): Promise<AxiosResponse<void>> {
    return this.client.post('/tokens', data);
  }

  async updateToken(data: UpdateTokenRequest): Promise<AxiosResponse<void>> {
    return this.client.put(`/tokens/${data.tokenName}`, data);
  }

  async deleteToken(name: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/tokens/${name}`);
  }

  // Transactions Endpoints
  async getTransactions(qs: string): Promise<AxiosResponse<any>> {
    return this.client.get(`/txs/?${qs}`);
  }

  async getTrade(tradeId: string): Promise<AxiosResponse<Trade>> {
    return this.client.get(`/txs/${tradeId}`);
  }

  async restartTxPeer(peer: TxPeerType): Promise<AxiosResponse<any>> {
    return this.client.post(`/txs/peers/${peer}/restart`);
  }

  async setPin(data: UpdatePinEventData): Promise<AxiosResponse<any>> {
    return this.client.put(`/txs/pins`, data);
  }

  async setSellPin(data: UpdatePinEventData): Promise<AxiosResponse<any>> {
    return this.client.put(`/txs/pins/sell`, data);
  }

  async forceBuy(data: WorkerIterationResult): Promise<AxiosResponse<any>> {
    return this.client.post(`/txs/force`, data);
  }

  async getSell(tradeId: string): Promise<AxiosResponse<Sell>> {
    return this.client.get(`/txs/sell/${tradeId}`);
  }

  async updateSell(data: Sell): Promise<AxiosResponse<any>> {
    return this.client.put(`/txs/sell/${data.tradeId}`, data);
  }

  async triggerManualTx(params: TriggerManualTx): Promise<AxiosResponse<any>> {
    return this.client.post(`/txs/manual`, params);
  }

  // Workers Endpoints
  async startWorkers(): Promise<AxiosResponse<void>> {
    return this.client.post('/workers/start');
  }

  async stopWorkers(): Promise<AxiosResponse<void>> {
    return this.client.post('/workers/stop');
  }
}

const API = new ApiService();
export { API };
